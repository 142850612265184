import PropTypes from 'prop-types';
// material
//
import { useSelector } from 'react-redux';
import LightThemeProvider from './light';
import DarkThemeProvider from './dark';
import { getDarkModeStatus } from '../store/reducers/layout_reducer';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {

  const isDarkMode = useSelector(state => getDarkModeStatus(state));

  return (
    isDarkMode ? <DarkThemeProvider>{children}</DarkThemeProvider> : <LightThemeProvider>{children}</LightThemeProvider>
  )
}
