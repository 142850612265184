import { useNavigate } from 'react-router-dom';
// @mui
import { Grid2 } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../../../../components/Page';
import Carousel from '../../../../components/Carousel';
import { getBannerImages } from '../../../../store/reducers/config_reducer';

import './CityPage.css';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import { APP_NAME } from '../../../../constants/app_constants';

export default function CityPage() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const bannerImages = useSelector(state => getBannerImages(state)); 

  return (
    <Page title={APP_NAME}>
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'header header-transparent'} container style={{minHeight:'40px', width:'100%'}}>
                <Header />
            </Grid2>

            <Footer />
        </Grid2>
    </Page>
      
  );
}
