import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { setUser } from '../../store/reducers/user_reducer';
import axios from '../../utils/axios-configure';
import {  API_FOLLOW_CATEGORY, API_GET_CATEGORY_DETAILS, API_GET_CATEGORY_LIST } from "../endpoints";
import {getAccessTokenFromCookie} from './session'
import { SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { PROFILE_UPDATE } from '../../constants/message_constants';
import { setCategoryData, setCategoryDetail, setFeaturedCategoryData, updateCategoryFollowStatus } from '../../store/reducers/category_reducer';

export const fetchCategoryList = (dispatch, cookies, page=1, count=4) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies);
    axios({
        method: "get",
        url: `${API_GET_CATEGORY_LIST}?page=${page}&count=${count}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(setCategoryData({data: response.data.data, pagination: response.data.pagination}));
        }else{
            dispatch(setCategoryData({data: [], pagination: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setCategoryData({data: [], pagination: {}}));
        dispatch(setLoadingStatus({status: false}));  
    });

}


export const fetchFeaturedCategoryList = (dispatch, cookies, page=1, count=4) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "get",
        url: `${API_GET_CATEGORY_LIST}?page=${page}&count=${count}&is_featured=1`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(setFeaturedCategoryData({data: response.data.data, pagination: response.data.pagination}));
        }else{
            dispatch(setFeaturedCategoryData({data: [], pagination: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setFeaturedCategoryData({data: [], pagination: {}}));
        dispatch(setLoadingStatus({status: false}));  
    });

}


export const fetchCategoryDetail = (dispatch, cookies, categoryId) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "get",
        url: `${API_GET_CATEGORY_DETAILS}?category_id=${categoryId}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            console.log(response.data);
            dispatch(setCategoryDetail({data: response.data.data}));
        }else{
            dispatch(setCategoryDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setCategoryDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });

}

export const followCategory = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    
    axios({
        method: "post",
        url: `${API_FOLLOW_CATEGORY}`,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(updateCategoryFollowStatus({category_id: data.category_id, status: data.follow}))
        }else{
            // dispatch(setEventDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        // dispatch(setEventDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });

}