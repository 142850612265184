import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// import Blog from './pages/Blog';
// import User from './pages/User';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register/Register';
import Profile from './pages/Profile/Profile';
import AuthLayout from './layouts/auth/AuthLayout';
// import TermsAndConditions from './pages/TermsAndConditions';
// import Payment from './pages/Payment';
// import OrderDetails from './pages/OrderDetails';
// import Support from './pages/Support';
// import Settings from './pages/Settings';
// import Receipt from './pages/Receipt';
// import Products from './pages/Products';
// import DashboardApp from './pages/DashboardApp';
// import Menu from './pages/Menu';
// import MyCorporateOrders from './pages/MyCorporateOrders';
// import MyCorporateMeals from './pages/MyCorporateMeals';
// import MyCalendar from './pages/MyCalendar';
// import MyPlan from './pages/MyPlan';
// import Nutritionist from './pages/Nutritionist';
// import Workout from './pages/Workout';
// import MyCorporateMealDetail from './pages/MyCorporateMealDetail';
// import Cart from './pages/Cart';

import Layout1 from './layouts/Layout1';
import Event from './pages/Event/Event';
import Join from './pages/Join/Join';
import AddEventTicket from './pages/AddEventTicket/AddEventTicket';
import Explore from './pages/Explore/Explore';
import SuccessPage from './pages/Success/SuccessPage';
// import QuickCheckout from './pages/QuickCheckout';
// import {Feedback} from './pages/Feedback';

// ----------------------------------------------------------------------

export default function Router() {

  // const location = useLocation();

  // return <Routes location={location} key={location.pathname}>
  //   <Route index element={<Login />} />
  //   <Route path="menu" element={<Menu />} />
  //   <Route path="cart" element={<Cart />} />
  // </Routes>;

  return useRoutes([
    {
      path: '/login/',
      element: <AuthLayout />,
      children: [
        // { path: '/', element: <Navigate to="/login" /> },
        { path: '', element: <Login /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/profile/',
      element: <Layout1 />,
      children: [
        { path: '', element: <Profile /> }, 
      ],
    }, 
    {
      path: '/event/',
      element: <Layout1 />,
      children: [ 
        { path: ':event_id', element: <Event /> }, 
        { path: '*', element: <Event /> }, 
      ],
    },
    {
      path: '/join/',
      element: <Layout1 />,
      children: [ 
        { path: '', element: <Join /> },
        { path: '*', element: <Join /> },
      ],
    },
    {
      path: '/success/',
      element: <Layout1 />,
      children: [ 
        { path: '', element: <SuccessPage /> },
        { path: '*', element: <SuccessPage /> },
      ],
    },    {
      path: '/addtickets/',
      element: <Layout1 />,
      children: [
        { path: 'event/:event_id', element: <AddEventTicket /> },
        { path: '*', element: <Navigate to="/join" /> },
      ],
    },
    {
      path: '/explore/',
      element: <Layout1 />,
      children: [
        { path: '', element: <Explore /> },
      ],
    },
    { path: '404', element: <NotFound /> },
    {
      path: '/',
      element: <Layout1 />,
      children: [
        { path: '/', element: <Home /> },
        { path: ':category/', element: <Home /> },
        { path: ':category/:tag_1/', element: <Home /> },
        { path: ':category/:tag_1/:tag_2/', element: <Home /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
