// export const ENV='production'; // dev or production

import { APP_ENV } from "../constants/app_constants";

export let API_BASE_URL="http://127.0.0.1:5000/";
// export let API_BASE_URL="http://192.168.1.2:5000/";

if (APP_ENV === 'staging') {
    API_BASE_URL="https://staging.tickit.world/";
}

if (APP_ENV === 'production') {
    API_BASE_URL="https://api.tickit.world/";
}
// export const API_BASE_URL="http://52.66.87.4/api/";
// export const API_BASE_URL="http://35.154.122.166/api/";
// export const API_BASE_URL="https://kitchen.greckle.io/";
// export const API_BASE_URL="https://staging.greckle.io/";
// export const API_BASE_URL="http://127.0.0.1:5000/";

// export const SSE_URL = "https://staging.the-bytes.com/stream"
export const SSE_URL = "http://52.66.87.4/stream"

// authentication
export const API_SEND_OTP="v1/otp";
export const API_VERIFY_OTP="v1/otp";
export const API_GENERATE_ACCESS_TOKEN="v1/oauth/token";
export const API_CREATE_SESSION = "v1/sessions/add";
export const API_GET_SESSION="v1/sessions"; 

// User
export const API_GET_USER_DETAILS="v1/users/";
export const API_UPDATE_USER_DETAILS="v1/users/";

// Settings
export const API_GET_SITE_CONFIG = "v1/settings";

// Page
export const API_GET_PAGE_DETAILS = "v1/page/deatails";

// Event
export const API_GET_EVENT_LIST = "v1/event/list";
export const API_GET_EVENT_DETAILS = "v1/event/detail";
export const API_FOLLOW_EVENT = "v1/event/follow";

// Category
export const API_GET_CATEGORY_LIST = "v1/category/list";
export const API_GET_CATEGORY_DETAILS = "v1/category/detail";
export const API_FOLLOW_CATEGORY = "v1/category/follow";

// Artist
export const API_GET_ARTIST_LIST = "v1/artist/list";
export const API_GET_ARTIST_DETAILS = "v1/artist/detail";
export const API_FOLLOW_ARTIST = "v1/artist/follow";

// Listing
export const API_GET_LISTING_FOR_EVENT = "v1/listing/";
export const API_ADD_LISTING = "v1/listing/";
export const API_FOLLOW_LISTING = "v1/listing/follow";

// Newsletter
export const API_SUBSCRIBE_NEWSLETTER = "v1/newsletter/subscribe";

// Contact us
export const API_SAVE_QUERY = "v1/query";

// Corporates
export const API_GET_CORPORATE_LIST="v1/corporates"; 

// Corporate Menu
export const API_FETCH_MENU="v1/menu";

// Corporate Cart
export const API_GET_CART_CORPORATE = "v1/corporate_cart";
export const API_UPDATE_CART_CORPORATE = "v1/corporate_cart";

// Corporate Order
export const API_GENERATE_ORDER_ID_CORPORATE = "v1/orders/fetch_corporate_order_id";
export const API_ADD_TRANSACTION_CORPORATE = "v1/transactions/add_transaction";
export const API_CONFIRM_ORDER_CORPORATE="v1/orders/confirm_corporate_order";
export const API_GET_USER_CORPORATE_ORDERS="v1/orders/";
export const API_GET_CORPORATE_ORDER_DETAILS="v1/orders/";
export const API_GET_USER_MEAL_HISTORY="v1/meals/";
export const API_GET_USER_CORPORATE_MEAL ="v1/orders/corporate_meals";

// Cart
export const API_GET_CART = "v1/cart";
export const API_UPDATE_CART = "v1/cart";

// Order
export const API_GENERATE_ORDER_ID = "v1/generate_order_id";
export const API_ADD_TRANSACTION = "v1/add_transaction";


// Terms and Conditions
export const API_TERMS_AND_CONDITIONS = "v1/help/terms-and-conditions";


