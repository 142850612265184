import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// import './Events.css';
import { MOCK_POPULAR_ARTIST_LIST } from '../../../../../data/local/mock';
import ArtistCard from '../../../../../components/Cards/ArtistCard/ArtistCard';
import { fetchFeaturedArtistList } from '../../../../../data/network/artist';
import { getFeaturedArtistList } from '../../../../../store/reducers/artist_reducer';
import Loader2 from '../../../../../components/Loader/loader_2/Loader2';

export default function PopularArtist() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [loadingArtists, setLoadingArtists] = useState(false);

  const artists = useSelector(state => getFeaturedArtistList(state)); 
  // const artists = MOCK_POPULAR_ARTIST_LIST;
 
  useState(() => {
    setLoadingArtists(false);
  },[artists]);

  useEffect(() => {
    // setLoadingArtists(true);
    fetchFeaturedArtistList(dispatch, cookies);
  },[]);

  return (
    <Grid2 className={'section section-artist'} container direction={'column'} px={4} py={2} style={{width:'100%'}}> 
        <Typography variant="h5" className={'section-title'}>Popular Artist</Typography>
        <Box height={16} />

        <Grid2 container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} rowSpacing={4} columnSpacing={4}>
        {
            !loadingArtists && artists && artists.map((artist, index) => {
                return <Grid2 container size={{xl:3, lg:3, md: 4, sm:6, xs:6}}>
                  <ArtistCard
                      key={index}
                      artist={artist}
                  />
                </Grid2>
            })
        }
        {
          loadingArtists && <Loader2 />
        }
        </Grid2>
    </Grid2>

  );
}
