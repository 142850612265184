import { setLoadingStatus, setSnackbarMessage, setSnackbarSeverity, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { setUser } from '../../store/reducers/user_reducer';
import axios from '../../utils/axios-configure';
import {  API_ADD_LISTING, API_FOLLOW_ARTIST, API_FOLLOW_LISTING, API_GET_ARTIST_DETAILS, API_GET_ARTIST_LIST, API_GET_LISTING_FOR_EVENT } from "../endpoints";
import {getAccessTokenFromCookie} from './session'
import { SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { PROFILE_UPDATE } from '../../constants/message_constants';
import { setArtistData, setArtistDetail, setFeaturedArtistData } from '../../store/reducers/artist_reducer';
import { setListingData, setListingResponse, setSaveListingResponse, updateListingFollowStatus } from '../../store/reducers/listing_reducer';

export const fetchListingForEvent = (dispatch, cookies, eventId, page=1, count=25) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies);
    axios({
        method: "get",
        url: `${API_GET_LISTING_FOR_EVENT}?page=${page}&count=${count}&event_id=${eventId}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            console.log(response.data);
            dispatch(setListingData({data: response.data.data, pagination: response.data.pagination}));
        }else{
            dispatch(setListingData({data: [], pagination: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setListingData({data: [], pagination: {}}));
        dispatch(setLoadingStatus({status: false}));  
    });

}

export const addListing = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "post",
        url: `${API_ADD_LISTING}`,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(setSaveListingResponse({data: response.data}))
            // dispatch(setSnackbarSeverity({}))
            
        }else{
            // dispatch(setEventDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        // dispatch(setEventDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });
}

export const followListing = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "post",
        url: `${API_FOLLOW_LISTING}`,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(updateListingFollowStatus({listing_id: data.listing_id, status: data.follow}))
        }else{
            // dispatch(setEventDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        // dispatch(setEventDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });

}