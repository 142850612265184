
export const MOCK_SETTINGS_EVENT_ABOUT_YOU = [
    {
        'id' : 1,
        'slug': 'neither-of-these',
        'title': 'Neither of these',
        'description': ''
    },
    {
        'id' : 2,
        'slug': 'event-organiser',
        'title': 'Event Organiser',
        'description': 'You are responsible for organising and managing the event, or receive some or all of the revenue from the event, or a person who is acting on behalf of one of the above.'
    },
    {
        'id' : 3,
        'slug': 'employed-by-us',
        'title': 'Employed by tickit',
        'description': 'This means you are either : an operator of, a parent undertaking of, employed or engaged by, tickit; or a person who is acting on behalf of one of the above.'
    }
];



export const MOCK_SETTINGS_EVENT_REQUIRED_TICKET_DETAILS = [
    {
        'id' : 1,
        'title': 'Limited or restricted view',
        'description': ''
    },
    {
        'id' : 2,
        'title': 'Ticket and meal package',
        'description': ''
    },
    {
        'id' : 3,
        'title': 'Aisle seat',
        'description': ''
    },
    {
        'id' : 4,
        'title': 'Includes VIP Pass',
        'description': ''
    },
    {
        'id' : 5,
        'title': 'Includes Parking',
        'description': ''
    },
    {
        'id' : 6,
        'title': 'Side or rear view',
        'description': ''
    },
    {
        'id' : 7,
        'title': 'Alcohol free area',
        'description': ''
    },
    {
        'id' : 8,
        'title': 'Standing only',
        'description': ''
    },
    {
        'id' : 9,
        'title': 'Restricted legroom',
        'description': ''
    },
    {
        'id' : 10,
        'title': 'Access to VIP lounge',
        'description': ''
    }
]


export const MOCK_SETTINGS_EVENT_RESTRICTION_DETAILS = [
    {
        'id' : 1,
        'title': 'Concession ticket - child',
        'description': ''
    },
    {
        'id' : 2,
        'title': 'Concession ticket - student',
        'description': ''
    },
    {
        'id' : 3,
        'title': 'Concession ticket - senior citizen',
        'description': ''
    },
    {
        'id' : 4,
        'title': 'Wheelchair user only',
        'description': ''
    },
    {
        'id' : 5,
        'title': 'Under 21 Ticket',
        'description': ''
    },
    {
        'id' : 6,
        'title': '21 and over Ticket',
        'description': ''
    },
    {
        'id' : 7,
        'title': 'Under 18 Ticket',
        'description': ''
    },
    {
        'id' : 8,
        'title': 'Over 18 Ticket',
        'description': ''
    },
    {
        'id' : 9,
        'title': 'No under 14s',
        'description': ''
    },
    {
        'id' : 10,
        'title': 'Original Purchaser\'s ID must be shown',
        'description': ''
    },
    {
        'id' : 11,
        'title': 'Meetup with Provider',
        'description': ''
    },
    {
        'id' : 12,
        'title': 'Resale not allowed',
        'description': ''
    },
    {
        'id' : 13,
        'title': 'Under 15s accompanied by an adult',
        'description': ''
    }
]

