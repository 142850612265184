
export const CART_ERROR = {
    NOT_SERVICABLE : 'Your cart is not servicable. Please check and try again.',
};

export const OTP_ERROR = {
    INVALID_MOBILE : 'You have entered an invalid mobile number.',
    UNABLE_TO_SEND_OTP : 'Unable to send OTP.',
    INVALID_OTP : 'You have entered an invalid OTP.',
    INCORRECT_OTP : 'Entered OTP is incorrect',
    UNABLE_TO_VERIFY_OTP : 'Unable to verify OTP.',
}; 

export const OTP_SUCCESS = {
    OTP_SEND_SUCCESS : 'OTP sent successfully.',
    OTP_VERIFY_SUCCESS : 'OTP verified successfully.', 
};

export const PROFILE_UPDATE = {
    SUCCESS : 'Profile updated successfully.',
    ERROR : 'Unable to update profile.', 
};

export const SEND_QUERY  = {
    SUCCESS : 'Your query has been submitted.',
    ERROR : 'Unable to send your query.', 
};

export const TERMS_AND_CONDITIONS  = {
    SUCCESS : 'Successfully fetched terms and conditions.',
    ERROR : 'Unable to fetch data.', 
};

export const NEWSLETTER_SUBSCRIBE = {
    SUCCESS : 'You have been successfully subscribed to the newsletter',
    ERROR : 'Unable to subscribe to newsletter.'
}

export const QUERY_SUBMIT = {
    SUCCESS : 'Your query have been successfully submitted.',
    ERROR : 'Unable to send your message.'
}

