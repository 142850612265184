import { createSlice } from '@reduxjs/toolkit';
import {PAGE_TYPE, SNACKBAR_SEVERITY } from '../../constants/app_constants';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    pageType: PAGE_TYPE.DEFAULT.key,
    categoryData: null,
    artistData: null,
    cityData: null,
  },
  reducers: {
    setPageType(state, action) {
        state.pageType = action.payload.data;
    },
    setCategoryData(state, action) {
      state.categoryData = action.payload.data;
    },
    setArtistData(state, action) {
        state.artistData = action.payload.data;
    }, 
    setCityData(state, action) {
        state.cityData = action.payload.data;
    }, 
    resetlayoutState(state, action){
      state.pageType = PAGE_TYPE.DEFAULT.key;
      state.categoryData = null;
      state.artistData =  null;
      state.cityData = null;
    }, 
  }
})

export const getPageType = (state) => {

    try{
        return state.page.pageType;
    }catch(e){
        return PAGE_TYPE.DEFAULT.key;
    } 
}


export const getCategoryData = (state) => {

    try{
        return state.page.categoryData;
    }catch(e){
        return null;
    } 
}

export const getArtistData = (state) => {

    try{
        return state.page.artistData;
    }catch(e){
        return null;
    } 
}

export const getCityData = (state) => {

    try{
        return state.page.cityData;
    }catch(e){
        return null;
    } 
}

 
export const { setPageType, setCategoryData, setArtistData,
    setCityData, 
} = pageSlice.actions

export default pageSlice.reducer