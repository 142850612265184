import ReactGA from 'react-ga4';

export const pageview = async ({page, title}) => {
    ReactGA.send({ hitType: "pageview", page: page, title: title });
}

export const logEvent = async (props) => {
    
    const {category, action, label, value=1, nonInteraction=true, transport="xhr"} = props;
    ReactGA.event({
        category: category,
        action: action,
        label: label, // optional
        value: value, // optional, must be a number
        nonInteraction: nonInteraction, // optional, true/false
        transport: transport, // optional, beacon/xhr/image
    });
}


