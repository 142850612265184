import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, IconButton, Tooltip, Button } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Iconify from '../../../components/Iconify';

import AisleSeatIcon from '../../../assets/icons/aisle-seat.svg';
import PerksIcon from '../../../assets/icons/perks.svg';
import CautionIcon from '../../../assets/icons/caution.svg';
import ParkingIcon from '../../../assets/icons/parking.svg';
import StandingSeatIcon  from '../../../assets/icons/standing-seat.svg';
import ClearViewIcon  from '../../../assets/icons/clear-view.svg';
// import InfoIcon  from '../../../assets/icons/info.svg';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { getLoginStatus } from '../../../store/reducers/user_reducer';
import { setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { followListing } from '../../../data/network/listing';
import Button1 from '../../../components/Button/Button1/Button1';
import AddIcon from '@mui/icons-material/Add';
import { logEvent } from '../../../utils/ga';

export default function TicketListCard({ticket}) { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const isLoggedIn = useSelector(state => getLoginStatus(state));

  const handleFollowListing = (e, follow) => {
      e.stopPropagation();
      logEvent({'category': 'event_listing_interest', 'action': ticket?.id, 'label': follow ? 'follow' : 'unfollow'});
      if (isLoggedIn){
          followListing(dispatch, cookies, {'listing_id': ticket?.id, 'follow': follow});
      }else{
          // show login modal
          dispatch(setLoginModalVisibilityStatus({'status' : true}));
      }
  }
  useEffect(() => {
    //   fetchEvents(dispatch, cookies);
  },[]);

  return (
    <Card sx={{width:'100%', padding:1, borderRadius:2}}>
        <Grid2 container direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} sx={{height:'100%'}}>
            <Grid2 container direction={'column'} size={8}>
                <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    {
                        ticket?.rating &&
                        ticket?.rating >= 8
                        && <>
                            <Card sx={{backgroundColor:'#00865A', py:0.5, px:1}}>
                                <Grid2 container alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant={'body1'} color='#fff' fontSize={10}>
                                        {ticket?.rating}
                                    </Typography>
                                </Grid2>
                            </Card>
                            <Typography variant={'body1'} fontWeight={600} fontSize={14} color='#00865A' px={0.5}>
                                {`Amazing`}
                            </Typography>
                        </>
                    }

                    {
                        ticket?.rating && 
                        ticket?.rating >= 6.5 &&  ticket?.rating < 8
                        && <>
                            <Card sx={{backgroundColor:'#3e8618',py:0.5, px:1}}>
                                <Grid2 container alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant={'body1'} color='#fff' fontSize={10}>
                                        {ticket?.rating}
                                    </Typography>
                                </Grid2>
                            </Card>
                            <Typography variant={'body1'} fontWeight={600} fontSize={14} color='#3e8618' px={0.5}>
                                {`Great`}
                            </Typography>
                        </>
                    }

                    {
                        ticket?.rating && 
                        ticket?.rating >= 4 &&  ticket?.rating < 6.5
                        && <>
                            <Card sx={{backgroundColor:'#6e7625', py:0.5, px:1}}>
                                <Grid2 container alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant={'body1'} color='#fff' fontSize={10}>
                                        {ticket?.rating}
                                    </Typography>
                                </Grid2>
                            </Card>
                            <Typography variant={'body1'} fontWeight={600} fontSize={14} color='#6e7625' px={0.5}>
                                {`Good`}
                            </Typography>
                        </>
                    }

                </Grid2>
                <Typography variant={'h7'} fontWeight={700}>
                    {ticket?.ticket_category?.title}
                </Typography>

                
                {/* {
                    ticket?.ticket_category?.aisle_seat
                    && <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                            <img src={AisleSeatIcon} width={14} />
                            <Typography variant={'subtitle2'} fontSize={10} px={0.5}>
                                Aisle seat
                            </Typography>
                            <Tooltip title={'Aisle seats mean quick and easy access to your seats, as these are close to walkaway.'}>
                                <IconButton sx={{padding:0}}>
                                    <InfoIcon sx={{fontSize: '10px'}} />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                }
                
                {
                    ticket?.ticket_category?.clear_view
                    && <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                            <img src={ClearViewIcon} width={14} />
                            <Typography variant={'subtitle2'} fontSize={10} px={0.5}>
                                Clear View
                            </Typography>
                            <Tooltip title={'You will have unrestricted view of the show.'}>
                                <IconButton sx={{padding:0}}>
                                    <InfoIcon sx={{fontSize: '10px'}} />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                }
                

                {
                    ticket?.ticket_category?.parking_included
                    && <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                            <img src={ParkingIcon} width={14} />
                            <Typography variant={'subtitle2'} fontSize={10} px={0.5}>
                                Parking Included
                            </Typography>
                            <Tooltip title={'You will be able to park your vehicles for free, as this is part of the package.'}>
                                <IconButton sx={{padding:0}}>
                                    <InfoIcon sx={{fontSize: '10px'}} />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                }
                

                {
                    ticket?.ticket_category?.perks && ticket?.ticket_category?.perks.map((perk) => {
                        return <>
                            <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                                <img src={PerksIcon} width={14} />
                                <Typography variant={'subtitle2'} fontSize={10} px={0.5}>
                                    {perk.title}
                                </Typography>
                                {
                                    perk.description && perk.description !== '' && <Tooltip title={perk.description}>
                                        <IconButton sx={{padding:0}}>
                                            <InfoIcon sx={{fontSize: '10px'}} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid2>
                        </>;
                    })
                }

                {
                    ticket?.ticket_category?.caution && ticket?.ticket_category?.caution.map((caution) => {
                        return <>
                            <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                                <img src={CautionIcon} width={14} />
                                <Typography variant={'subtitle2'} fontSize={10} px={0.5}>
                                    {caution.title}
                                </Typography>
                                {
                                    caution.description && caution.description !== '' && <Tooltip title={caution.description}>
                                        <IconButton sx={{padding:0}}>
                                            <InfoIcon sx={{fontSize: '10px'}} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid2>
                        </>;
                    })
                }
                
                {
                    ticket?.ticket_category?.standing_only
                    && <Grid2 container justifyContent={'flex-start'} alignItems={'center'}>
                            <img src={StandingSeatIcon} width={14} />
                            <Typography variant={'subtitle1'} fontSize={10} px={0.5}>
                                Standing Only
                            </Typography>
                                <Tooltip title={'This tickets are standing only'}>
                                    <IconButton sx={{padding:0}}>
                                        <InfoIcon sx={{fontSize: '10px'}} />
                                    </IconButton>
                                </Tooltip>
                        </Grid2>
                } */}

                {/* <Typography variant={'body1'} color={'red'} py={1} fontSize={12}>
                    {
                        `${ticket?.ticket_count} tickets remaining in this listing`
                    }
                </Typography> */}

                <Typography variant={'h6'} py={1}>
                    {`Entry for ${ticket?.ticket_count}`}
                </Typography>

                <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>                
                    <Typography variant={'body1'} fontWeight={700} fontSize={16}>
                        {`INR ${ticket?.listing_value}`}
                    </Typography>
                    <Typography variant={'body1'} px={0.5} fontSize={12}>
                        {`each`}
                    </Typography>
                </Grid2>

            </Grid2>
            <Grid2 container direction={'column'} size={4} justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}} rowSpacing={4}>   
                <Grid2 container>
                    {
                        !ticket?.is_following
                        ? <Button className={`btn btn-transparent`} variant={'outlined'} onClick={(e) => handleFollowListing(e, true)}
                            sx={{height: '40px',width: '120px'}}>
                        Follow
                        </Button>
                        : <Button className={`btn btn-success`} variant={'contained'} onClick={(e) => handleFollowListing(e, false)}
                            sx={{height: '40px',width: '120px'}}>
                        Following
                        </Button>
                    }   

                    {/* <Button1 variant={'outlined'} title={'Follow'} startIcon={<AddIcon />} size={'small'} /> */}
                </Grid2>
            </Grid2>
        </Grid2>
        
    </Card>
  );
}
