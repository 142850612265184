import { createSlice } from '@reduxjs/toolkit'
// import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    featuredCategoryData: null,
    categoryData: null,
    categoryDetail: null,
  },
  reducers: {
    setFeaturedCategoryData(state,action) {
      state.featuredCategoryData = {
        'data': action.payload.data,
        'pagination': action.payload.pagination 
      }
    },
    setCategoryData(state,action) {
      state.categoryData = {
        'data': action.payload.data,
        'pagination': action.payload.pagination 
      }
    },
    setCategoryDetail(state,action) {
      state.categoryDetail = action.payload.data; 
    },
    updateCategoryFollowStatus(state, action) {
        try{
            const _featuredCategoryData = state.featuredCategoryData.data;
            _featuredCategoryData.forEach(element => {
                if (element.slug == action.payload.category_id){
                    element.is_following = action.payload.status
                }
            });
            state.featuredCategoryData.data = _featuredCategoryData;
        }catch(e){
            console.log(e);
        }
    },    
    resetCategoryState(state, action){
      state.featuredCategoryData = null;
      state.categoryData = null;
      state.categoryDetail = null;
    }
  }
})

export const getFeaturedCategoryData = (state) => {
    try{
        return state.category.featuredCategoryData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getFeaturedCategoryList = (state) => {
    try{
        return state.category.featuredCategoryData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getFeaturedCategoryPagination = (state) => {
    try{
        return state.category.featuredCategoryData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}


export const getCategoryData = (state) => {
    try{
        return state.category.categoryData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getCategoryList = (state) => {
    try{
        return state.category.categoryData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getCategoryPagination = (state) => {
    try{
        return state.category.categoryData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getCategoryDetail = (state) => {
    try{
        return state.category.categoryDetail;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const { setCategoryData, setFeaturedCategoryData, setCategoryDetail, resetCategoryState, updateCategoryFollowStatus
} = categorySlice.actions
export default categorySlice.reducer