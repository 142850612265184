import Iconify from '../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const MENU_NAV = {
    key: 'menu',
    title: 'menu',
    path: '/menu',
    icon: getIcon('eva:file-text-outline'),
    activeIcon: getIcon('eva:file-text-fill'),
    inActiveIcon: getIcon('eva:file-text-outline'),
    visibility: [
        'all'
    ]
};
  

const MY_ORDERS_NAV = {
    key: 'my-orders',
    title: 'my orders',
    path: '/my-orders',
    icon: getIcon('eva:person'),
    activeIcon: getIcon('eva:person'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};

const MY_MEALS_NAV = {
    key: 'my-meals',
    title: 'my meals',
    path: '/my-meals',
    icon: getIcon('eva:clock-outline'),
    activeIcon: getIcon('eva:clock-fill'),
    inActiveIcon: getIcon('eva:clock-outline'),
    visibility: [
        'all'
    ]
};


const MY_CALENDAR_NAV = {
    key: 'my-calendar',
    title: 'my calendar',
    path: '/my-calendar',
    icon: getIcon('eva:person'),
    activeIcon: getIcon('eva:person'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};



const MY_PLAN_NAV = {
    key: 'my-plan',
    title: 'my plan',
    path: '/my-plan',
    icon: getIcon('eva:person'),
    activeIcon: getIcon('eva:person'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};

const NUTRITIONIST_NAV = {
    key: 'nutritionist',
    title: 'nutritionist',
    path: '/nutritionist',
    icon: getIcon('eva:person'),
    activeIcon: getIcon('eva:person'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};

const WORKOUT_NAV = {
    key: 'workout',
    title: 'workout',
    path: '/workout',
    icon: getIcon('eva:person'),
    activeIcon: getIcon('eva:person'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};

const SUPPORT_NAV = {
    key: 'support',
    title: 'support',
    path: '/support',
    icon: getIcon('eva:email-outline'),
    activeIcon: getIcon('eva:email-fill'),
    inActiveIcon: getIcon('eva:email-outline'),
    visibility: [
        'all'
    ]
};

const SETTING_NAV = {
    key: 'settings',
    title: 'settings',
    path: '/settings',
    icon: getIcon('eva:settings-2-outline'),
    activeIcon: getIcon('eva:settings-2-outline'),
    inActiveIcon: getIcon('eva:settings-2-outline'),
    visibility: [
        'all'
    ]
};

const PROFILE_NAV = {
    key:  'profile',
    title: 'profile',
    path: '/profile',
    icon: getIcon('eva:person-outline'),
    activeIcon: getIcon('eva:person-fill'),
    inActiveIcon: getIcon('eva:person-outline'),
    visibility: [
        'all'
    ]
};

const CART_NAV = {
    key:  'cart',
    title: 'cart',
    path: '/cart',
    icon: getIcon('eva:shopping-cart-outline'),
    activeIcon: getIcon('eva:shopping-cart-fill'),
    inActiveIcon: getIcon('eva:shopping-cart-outline'),

    visibility: [
        'all'
    ]
};
 
/** Corporate Manager Routes Ends here */

export const navigationConfig = [
    MENU_NAV,
    // MY_ORDERS_NAV,
    MY_MEALS_NAV,
    // MY_CALENDAR_NAV,
    // MY_PLAN_NAV,
    // NUTRITIONIST_NAV,
    // WORKOUT_NAV,
    SUPPORT_NAV,
    // SETTING_NAV,
    PROFILE_NAV, 
    CART_NAV,
];
