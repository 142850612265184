import React, { useEffect, useState } from 'react';
import Iconify from '../../Iconify';
import { Box, Card, CardActions, CardContent, CardMedia, Grid2, IconButton, Stack, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import './EventCard.css';
import { useNavigate } from 'react-router-dom';
import { followEvent } from '../../../data/network/event';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getLoginStatus } from '../../../store/reducers/user_reducer';
import { setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { getSingleS3Url } from '../../../utils/aws';
import { isMobile } from 'react-device-detect';
import { EventFallbackImage } from '../../../constants/file_constants';
import { logEvent } from '../../../utils/ga';

export default function EventCard({index, event}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleFollowEvent = (e, follow) => {
        e.stopPropagation();
        logEvent({'category':'event_follow', 'action':event?.slug, 'label': event?.display_name});

        if (isLoggedIn){
            followEvent(dispatch, cookies, {'event_id': event?.slug, 'follow': follow});
        }else{
            // show login modal
            dispatch(setLoginModalVisibilityStatus({'status' : true}));
        }
    }

    const [isFeaturedImageLoading, setIsFeaturedImageLoading] = useState(false);
    const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
   
    const fetchEventImagesUrl = async () => {
      try{
        setIsFeaturedImageLoading(true);
        const _data = {
          'file_path' : isMobile ? event?.featured_image?.file_path_mobile : event?.featured_image?.file_path_mobile,
          'file_type' : isMobile ? event?.featured_image?.file_type_mobile : event?.featured_image?.file_type_mobile,
        } 
        
        const _featuredImageUrl = await getSingleS3Url(_data);
        setFeaturedImageUrl(_featuredImageUrl);
      }catch(e) {
        console.log(e);
      }
      setIsFeaturedImageLoading(false);
    }

    useEffect(() => {
        fetchEventImagesUrl();
    },[]);

    return (
        <Stack container flexDirection={'column'} className={'event-card'}
            sx={{ 
                background:'transparent!important',
                width:'100%',
            }}
            onClick={() => {
                logEvent({'category':'event_clicked', 'action':event?.slug, 'label': event?.display_name});
                navigate(`/event/${event.slug}`)
            }
        }>
            {
                (!isFeaturedImageLoading && featuredImageUrl) ? <img className={'event-card-img'} src={featuredImageUrl} alt={event?.title} height={'150'} width={'100%'}
                    onError={(e) => {
                        e.currentTarget.src = EventFallbackImage
                        e.currentTarget.style = 'opacity: 0.5'
                    }}
                />  :    <img className={'event-card-img'} src={EventFallbackImage} alt={event?.title} height={'150'} width={'100%'}
                        style={{opacity:'0.5'}}
                    />       
            }
            <Grid2>
                <Typography variant="body1" py={2} fontWeight={500}>
                    {event?.display_name}
                </Typography>
            </Grid2>

            <Box className={'event-card-follow-icon'} onClick={(e) => handleFollowEvent(e, !event?.is_following)}>
                <IconButton aria-label="add to favorites">
                    {event?.is_following ? <FavoriteIcon color={'error'} /> : <FavoriteIcon />}
                </IconButton>
            </Box>
            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions> */}
            
        </Stack>
    );
}