import { setUser } from '../../store/reducers/user_reducer';
import { setSendOTPResponse, setVerifyOTPResponse } from '../../store/reducers/auth_reducer';
import axios from '../../utils/axios-configure'; 
import { API_GET_SITE_CONFIG, API_SAVE_QUERY, API_SEND_OTP, API_SUBSCRIBE_NEWSLETTER, API_VERIFY_OTP } from "../endpoints";
import { fetchUserDetails } from './user';
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { DEFAULT_SITE_CONFIG, SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { OTP_ERROR, OTP_SUCCESS, QUERY_SUBMIT } from '../../constants/message_constants';
import { getDeviceInfo } from '../../utils/device_helper';
import { setSiteConfigResponse } from '../../store/reducers/config_reducer';

export const saveQuery = async (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));

    let url = `${API_SAVE_QUERY}`;

    axios({
        method: "post",
        url,
        data,
        headers: { "Content-Type": "application/json",
            //  "X-Device-Info": `${deviceInfo}`
            },
    })
    .then((response)=> {

        if(response.status === 200 && response.data.success) {
            dispatch(setSnackbarMessage({'message': QUERY_SUBMIT.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
            // dispatch(setSiteConfigResponse({data:response.data.data}));
        }else{
            dispatch(setSnackbarMessage({'message': QUERY_SUBMIT.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));             
            // dispatch(setSiteConfigResponse({data: DEFAULT_SITE_CONFIG}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((error)=> {
        console.log(error);
        dispatch(setLoadingStatus({status: false}));  
        // dispatch(setSiteConfigResponse({data: DEFAULT_SITE_CONFIG}));
        dispatch(setSnackbarMessage({'message': QUERY_SUBMIT.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}
