import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, Button, Stack, Tabs, Tab } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

import './Event.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { MOCK_EVENT } from '../../data/local/mock';
import EventInformation from './components/EventInformation';
import TicketList from './components/TicketList';
import { fetchEventDetail } from '../../data/network/event';
import { getEventDetail } from '../../store/reducers/event_reducer';
import { BrowserView, MobileView } from 'react-device-detect';
import HeaderDark from '../../components/Header/HeaderDark';
import AddTicketModal from '../../components/Modal/AddTicketModal/AddTicketModal';
import EventInformationAbout from './components/EventInformationAbout';
import ReactGA from 'react-ga4';
import { logEvent } from '../../utils/ga';

// ----------------------------------------------------------------------

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </Box>
  );
}

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

export default function Event() { 

  const location = useLocation();
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Event" });

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  
  const [selectedTab, setSelectedTab] = useState(0);

  const [isAddTicketModalVisible, setIsAddTicketModalVisible] = useState(false);
  
  const params = useParams();
  
  const event = useSelector((state) => getEventDetail(state));
//   const event = MOCK_EVENT;

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  }

  const handleAddTicketButtonClick = (e) => {
    logEvent({'category':'event_listing_add', 'action':event?.slug, 'label': event?.display_name});
    setIsAddTicketModalVisible(true);
  }

  const handleAddTicketModalClose = (e) => {
    setIsAddTicketModalVisible(false);
  }

  useEffect(() => {
    // setEventId(params.event_id);
    const _eventId = params.event_id;
    fetchEventDetail(dispatch, cookies, _eventId);
  },[])

  return (
    <Page title="Event">
      <BrowserView>
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'header'} container style={{height:'110px', width:'100%'}}>
                <Header />
            </Grid2>

            <Grid2 container direction={'row'} sx={{margin:'0!important',width:'100%!important', height:'calc(100vh - 110px)'}}>
                <Grid2 className={'event-information-container'} size={{lg:7, xl: 6, md:6, sm:12, xs: 12}} sx={{height:'calc(100vh - 110px)', overflow:'hidden', overflowY:'scroll'}}>
                    <EventInformation event={event} />
                </Grid2>
                <Grid2 className={'ticket-list-container'} size={{lg:5, xl: 6, md:6, sm:12, xs: 12}} sx={{backgroundColor:'#f6f8f9', height:'calc(100vh - 110px)', overflow:'hidden', overflowY:'scroll'}}>
                    <TicketList event={event} />
                </Grid2>
            </Grid2>
        </Grid2>
        <Box sx={{position:'fixed', bottom:0, left:'50%', zIndex: 10, transform: 'translate(-50%, -50%)', margin: '0 auto'}}> 
          <Button className='btn btn-primary' variant={'contained'} sx={{borderRadius:1}} onClick={handleAddTicketButtonClick}>Add your ticket</Button>
        </Box>

      </BrowserView>
      <MobileView>
        <Box className={'header-transparent'} sx={{position:'absolute', top:0, left:0, right:0, zIndex: 10}}> 
          <HeaderDark />
        </Box>
        <Grid2 container direction={'column'} sx={{margin:'0!important',width:'100%!important'}}>
            <Grid2 paddingTop={'120px'} className={'event-information-container'} sx={{width:'100%', overflow:'hidden', overflowY:'scroll'}}>
                <EventInformation event={event} />
            </Grid2>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', width:'100%',  }}>
              <Tabs value={selectedTab} onChange={handleTabChange} aria-label="event tabs">
                <Tab label="About" {...a11yProps(0)} />
                <Tab label="Tickets" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={selectedTab} index={0} sx={{width:'100%', }}>
              <EventInformationAbout event={event} />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1} sx={{width:'100%'}}>
              <Grid2 className={'ticket-list-container'} size={{lg:4, xl: 4, md:6, sm:12, xs: 12}} sx={{backgroundColor:'#f6f8f9', height:'calc(100vh - 100px)', overflow:'hidden', overflowY:'scroll'}}>
                <TicketList event={event} />
              </Grid2>
            </CustomTabPanel>
            {/* <Grid2 className={'ticket-list-container'} size={{lg:4, xl: 4, md:6, sm:12, xs: 12}} sx={{backgroundColor:'#f6f8f9', overflow:'hidden', overflowY:'scroll'}}>
                <TicketList event={event} />
            </Grid2> */}
        </Grid2>
        <Box sx={{position:'fixed', bottom:0, left:'50%', zIndex: 10, transform: 'translate(-50%, -50%)', margin: '0 auto'}}> 
          <Button className='btn btn-primary' variant={'contained'} sx={{borderRadius:1}} onClick={handleAddTicketButtonClick}>Add your ticket</Button>
        </Box>
      </MobileView>
      {
        isAddTicketModalVisible && <AddTicketModal event={event} open={isAddTicketModalVisible} onClose={handleAddTicketModalClose} />
      }
    </Page>
  );
}
