
import { Grid2 } from '@mui/material';
import './Loader2.css';
import {ClipLoader, RingLoader} from 'react-spinners';

export default function Loader2 ()  {
    return( 
        <Grid2 container my={8} justifyContent={'center'} sx={{width:'100%'}} >
            <ClipLoader />
        </Grid2>
    );
    
}
