import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, Grid2, Typography } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// icons
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

// components
import Page from '../../../../components/Page';
import Carousel from '../../../../components/Carousel';
import { getBannerImages } from '../../../../store/reducers/config_reducer';

import './ArtistPage.css';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import { APP_NAME } from '../../../../constants/app_constants';
import EventList from '../../../../components/EventList/EventList';
import { ARTIST_DATA, CATEGORY_DATA, FILTER_PARAMS, MOCK_EVENT } from '../../../../data/local/mock';
import HeaderDark from '../../../../components/Header/HeaderDark';
import Iconify from '../../../../components/Iconify';
export default function ArtistPage() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const bannerImages = useSelector(state => getBannerImages(state)); 

  const events = [
    MOCK_EVENT, MOCK_EVENT, MOCK_EVENT, MOCK_EVENT, MOCK_EVENT
  ]

  const artistData = ARTIST_DATA;

  const filters = FILTER_PARAMS;
  const pagination = {
    'page': 1,
    'per_page': 10,
    'total_pages': 13
  }

  const background = "https://static.vecteezy.com/system/resources/previews/024/603/816/non_2x/rock-music-concert-background-illustration-ai-generative-free-photo.jpg";

  return (
    <Page title={APP_NAME}>
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'artist-header'} container style={{minHeight:'50vh', width:'100%', backgroundImage: `url(${background})`}}>
                <HeaderDark />
                <Grid2 container direction={'column'} justifyContent={'center'} px={8}>
                  <Typography variant="h3">{artistData.title}</Typography>
                </Grid2>
            </Grid2>

              <Grid2 container my={4} px={8} direction={'row'} justifyContent={'center'} sx={{width:'100%'}} columnSpacing={4}>
              
                <Grid2 container size={{xl:9, lg: 8, md: 8, xs:12, sm: 12}} direction={'column'} justifyContent={'flex-start'} sx={{minHeight:'500px', width:'100%'}}>
                  <EventList events={events} pagination={pagination} filters={filters} />
                </Grid2>

                <Grid2 container size={{xl:3, lg: 4, md: 4, xs:12, sm: 12}} direction={'column'} justifyContent={'center'} sx={{width:'100%'}}>
                  <Card>
                    <Grid2 container direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'} sx={{width:'100%'}}>
                      <img src={artistData.image_url} alt={artistData.title} width={'100%'} height={'200px'} />

                      <Box px={2} py={1}>
                        <Typography variant={'h5'}>{artistData.title}</Typography>
                      </Box>

                      <Box padding={2}>
                        <Typography variant="body2">{artistData.description}</Typography>
                      </Box>

                      <Grid2 container px={2} py={1} direction={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{width:'100%'}}>
                        {
                          artistData.fb_url && artistData.fb_url !== '' && <FacebookOutlinedIcon fontSize={'large'} />
                        }
                      </Grid2>
                    </Grid2>
                  </Card>
                </Grid2>

               
              </Grid2>
            

            <Footer />
        </Grid2>
    </Page>
      
  );
}
