import React, { useEffect, useState } from 'react';
import Iconify from '../../Iconify';
import { Box, Card, CardActions, CardContent, CardMedia, Grid2, IconButton, Stack, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import './ArtistCard.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { followArtist } from '../../../data/network/artist';
import { getLoginStatus } from '../../../store/reducers/user_reducer';
import { setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { ArtistFallbackImage } from '../../../constants/file_constants';
import { getSingleS3Url } from '../../../utils/aws';
import { isMobile } from 'react-device-detect';
import { logEvent } from '../../../utils/ga';

export default function ArtistCard({key, artist}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const [isFeaturedImageLoading, setIsFeaturedImageLoading] = useState(false);
    const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
    
    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleFollowArtist = (e, follow) => {
        e.stopPropagation();
        logEvent({'category':'artist_follow', 'action':artist?.slug, 'label': artist?.display_name});

        if (isLoggedIn){
            followArtist(dispatch, cookies, {'artist_id': artist?.slug, 'follow': follow});
        }else{
            // show login modal
            dispatch(setLoginModalVisibilityStatus({'status' : true}));
        }
    }

    const fetchImagesUrl = async () => {
        try{
          setIsFeaturedImageLoading(true);
          const _data = {
            'file_path' : isMobile ? artist?.featured_image?.file_path_mobile : artist?.featured_image?.file_path_mobile,
            'file_type' : isMobile ? artist?.featured_image?.file_type_mobile : artist?.featured_image?.file_type_mobile,
          } 
          
          const _featuredImageUrl = await getSingleS3Url(_data);
          setFeaturedImageUrl(_featuredImageUrl);
        }catch(e) {
          console.log(e);
        }
        setIsFeaturedImageLoading(false);
    }
  
    useEffect(() => {
        fetchImagesUrl();
    },[]);

    return (
        <Stack key={key} container flexDirection={'column'} className={'event-card'}
            sx={{ background:'transparent!important', width:'100%' }} 
            onClick={() => {
                logEvent({'category':'artist_clicked', 'action':artist?.slug, 'label': artist?.display_name});
                // navigate(`${artist.link}`)
            }
        }>
            {
                
                (!isFeaturedImageLoading && featuredImageUrl) ? <img className={'artist-card-img'} src={featuredImageUrl} alt={artist?.title} height={'100'} width={'100%'} 
                    onError={(e) => {
                        e.currentTarget.src = ArtistFallbackImage;
                        e.currentTarget.style = 'opacity:0.5';
                    }}
                /> : <img className={'artist-card-img'} src={ArtistFallbackImage} alt={artist?.title} style={{opacity: 0.5}} height={'100'} width={'100%'} />           
            }

            
            <Grid2>
            <Typography variant="body1" py={2} fontWeight={500}>
                {artist.display_name}
                </Typography>
            </Grid2>

            <Box className={'artist-card-follow-icon'} onClick={(e) => handleFollowArtist(e, !artist.is_following)}>
                <IconButton aria-label="add to favorites">
                    {artist.is_following ? <FavoriteIcon color={'error'} /> : <FavoriteIcon />}
                </IconButton>
            </Box>
            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions> */}
            
        </Stack>
    );
}