import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import LoginForm from './components/LoginForm';
// import AuthSocial from '../sections/auth/AuthSocial';

import { getSession } from '../../data/network/session';
import { fetchUserDetails } from '../../data/network/user';
import { getLoginStatus, getUser } from '../../store/reducers/user_reducer';
import { getAfterLoginUrl } from '../../store/reducers/navigation_reducer';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '95%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const userDetails = useSelector(state => getUser(state)); 
  const afterLoginUrl = useSelector(state => getAfterLoginUrl(state)); 

  useEffect(() => {
    getSession(dispatch, cookies, removeCookie);
  },[]);
 
  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn]);

  useEffect(() => {
    if(isLoggedIn && userDetails && Object.keys(userDetails).length !== 0){
      if (afterLoginUrl === null) {
        navigate("/");
      }else{
        try{
          window.location.href = afterLoginUrl;
        }catch(e){
          navigate("/");
          console.log(e);
        }
      }
    }else{
      console.log(isLoggedIn);
    }
  },[userDetails]);

  return (
    <Page title="Login">
      <RootStyle>
        <Grid2  item container xs={12} sm={12} padding={2} md={12} lg={12} xl={12} justifyContent="center" alignItems={'center'} spacing={3}>
          <Grid2 item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card
              className='glass-box'
              sx={{
                py: 5,
                px: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: '#eee',
              }} 
            >
              <Box margin={4}/>

              <h2>Welcome Back!</h2>
              <Box margin={4}/>
              
              <Box margin={1}/>
              <LoginForm />
            </Card>
          </Grid2>
        </Grid2>
      </RootStyle>
    </Page>
  );
}
