import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// import './Events.css';
import { MOCK_EVENT_DATA } from '../../../data/local/mock';
import EventCard from '../../../components/Cards/EventCard/EventCard';
import EventInformationCarousel from './EventInformationCarousel';
import VenueInformation from './VenueInformation';

import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClockIcon from '@mui/icons-material/ScheduleOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import parse from 'html-react-parser';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { formatDate } from '../../../utils/date';
import { getS3Url, getSingleS3Url } from '../../../utils/aws';
import { isMobile } from 'react-device-detect';
import { EventFallbackImage } from '../../../constants/file_constants';

export default function EventInformation({event}) {

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [isFeaturedImageLoading, setIsFeaturedImageLoading] = useState(false);

  const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
 
  const fetchEventImagesUrl = async () => {
    try{
      setIsFeaturedImageLoading(true);
      const _data = {
        'file_path' : isMobile ? event?.featured_image?.file_path_mobile : event?.featured_image?.file_path_mobile,
        'file_type' : isMobile ? event?.featured_image?.file_type_mobile : event?.featured_image?.file_type_mobile,
      } 

      const _featuredImageUrl = await getSingleS3Url(_data);
      setFeaturedImageUrl(_featuredImageUrl);
      setIsFeaturedImageLoading(false);
    }catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    
      fetchEventImagesUrl();
  },[]);

  return (
    <Grid2 item container borderRadius={1} sx={{margin:'0!important',width:'100%!important'}}>

        <Grid2 container direction={'column'} style={{width:'100%'}}>
          <Grid2 container direction={'row'}>
            {/* <Grid2 container direction={'column'} size={4} py={2}>
              <EventInformationCarousel dataList={event.images} />
            </Grid2> */}
            <Grid2 container direction={'row'} justifyContent={'flex-start'} sx={{width:'100%'}}>
              <Grid2 container direction={'column'} size={7} padding={2} rowSpacing={1}>
                <Grid2 container direction={'row'} >
                  <Typography variant="h6" fontWeight={700}>{event?.display_name}</Typography>
                </Grid2>
                <Grid2 container direction={'row'} columnSpacing={0.5}>
                  <CalendarIcon />
                  <Typography variant="subtitle2">{formatDate(event?.start_date, "DD MMM YYYY")}</Typography>
                </Grid2>
                <Grid2 container direction={'row'} columnSpacing={0.5}>
                  <ClockIcon />
                  <Typography variant="subtitle2">{event?.start_time} onwards</Typography>
                </Grid2>
                <Grid2 container direction={'row'} columnSpacing={0.5}>
                  <LocationIcon />
                  <Typography variant="subtitle2">{event?.venue?.display_name}, {event?.venue?.city}</Typography>
                </Grid2>

              </Grid2>
              
              <Grid2 container direction={'column'} size={5}>
                {/* {
                  !isFeaturedImageLoading && <EventInformationCarousel dataList={featuredImageUrl} />
                } */}

                {
                  !isFeaturedImageLoading && featuredImageUrl 
                  ? <img className={'event-card-img'} src={featuredImageUrl} alt={event?.title} height={180}
                    onError={(e) => {
                    e.currentTarget.src = EventFallbackImage;
                    e.currentTarget.style = 'opacity: 0.5'
                  }}/>
                  : <img className={'event-card-img'} src={EventFallbackImage} alt={event?.title} height={180} style={{opacity: 0.5, backgroundColor:'#fff'}} />
                }
              </Grid2>

            </Grid2>
            {!isMobile && <Grid2 container direction={'column'} padding={2} size={12}>

              {
                event?.description && event?.description !== '' && <Grid2 container direction={'row'} py={1}>
                  <Typography variant="h6" fontWeight={700}>{'About'}</Typography>
                  {parse(event?.description)}
                </Grid2>
              }

              {
                event?.venue?.layout_image && event?.venue?.layout_image !== '' &&
                <Grid2 container direction={'column'} py={1}>
                  <Typography variant="h6" fontWeight={700}>{'Venue Layout'}</Typography>

                  <img src={event?.venue?.layout_image} width="300" height="300" />
                </Grid2>
              }

              {
                event?.terms && event?.terms.length >0 &&
                <Grid2 container direction={'row'}  py={1}>
                  <Typography variant="h6" fontWeight={700}>{'Terms and Conditions'}</Typography>
                  <List>
                    {
                      event?.terms?.map((term, i) => {
                        return (
                          <ListItem disablePadding>
                            <ListItemIcon sx={{minWidth:0}}>
                              <ArrowRightOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {term}
                            </ListItemText>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </Grid2>
              }

            </Grid2>
}
          </Grid2>

        </Grid2>
    </Grid2>
  );
}
