import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
// components


import { getSession } from '../../data/network/session';
import { fetchUserDetails } from '../../data/network/user';
import { getLoginStatus, getUser } from '../../store/reducers/user_reducer';
import { getAfterLoginUrl } from '../../store/reducers/navigation_reducer';
import { getBannerImages } from '../../store/reducers/config_reducer';

import './Home.css';
import { PAGE_TYPE } from '../../constants/app_constants';
import LandingPage from './components/landing/LandingPage';
import CityPage from './components/city/CityPage';
import CategoryPage from './components/category/CategoryPage';
import ArtistPage from './components/artist/ArtistPage';
import { getPageType } from '../../store/reducers/page_reducer';
import { fetchSiteConfig } from '../../data/network/settings';
import { _getSignedUrl, getS3Url } from '../../utils/aws';
import { BANNER_IMAGE_CONSTANTS } from '../../constants/file_constants';

export default function Home() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const isLoggedIn = useSelector(state => getLoginStatus(state));
  // const isLoggedIn = useSelector(state => getSelectedCorporate(state));
  const userDetails = useSelector(state => getUser(state)); 
  const afterLoginUrl = useSelector(state => getAfterLoginUrl(state)); 

  const bannerImages = useSelector(state => getBannerImages(state)); 

  // const pageType = useSelector(state => getPageType(state)); 

  const [pageType, setPageType] = useState('home')
  // const [pageType, setPageType] = useState('category')
  // const [pageType, setPageType] = useState('artist')
  // const [pageType, setPageType] = useState('city')

  const params = useParams();
  
  useEffect(() => {
    getSession(dispatch, cookies, removeCookie);
    fetchSiteConfig(dispatch, cookies);
  },[]);

  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn]);

  useEffect(() => {
    if(isLoggedIn && userDetails && Object.keys(userDetails).length !== 0){
      if (afterLoginUrl === null) {
        navigate("/");
      }else{
        try{
          window.location.href = afterLoginUrl;
        }catch(e){
          navigate("/");
          console.log(e);
        }
      }
    }
  },[userDetails]);

  useEffect(() => {
    if (params.length === 0){
      
    }else{
      // call api to fetch more details from url
      // Request Body
      //  {
      //   'category' : 'Concert-Tickets',
      //   'tag_1': 'Pop-and-Rock',
      //   'tag_2': 'Diljit-Dosanjh'
      // }
      
    }


  },[params])

  const changePageType = (_pageType) => {
    // setPageType(_pageType);
  }

  return (
    <>
      {
        (pageType === PAGE_TYPE.HOME.key) && <LandingPage />
      }
      {
        (pageType === PAGE_TYPE.CATEGORY.key) && <CategoryPage />
      }
      {
        (pageType === PAGE_TYPE.ARTIST.key) && <ArtistPage />
      }
      {
        (pageType === PAGE_TYPE.CITY.key) && <CityPage />
      }
    </>
  );
}
