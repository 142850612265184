import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// import './Events.css';
import { MOCK_EVENT_DATA } from '../../../data/local/mock';
import parse from 'html-react-parser';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

export default function EventInformationAbout({event}) {

  return (
    <Grid2 item container borderRadius={1} sx={{margin:'0!important',width:'100%!important'}}>

        <Grid2 container direction={'column'} style={{width:'100%'}} py={1}>
          <Grid2 container direction={'row'}>
            
            <Grid2 container direction={'column'} padding={2} size={12}>
              {
                event?.description && event?.description !== '' && 
                <Grid2 container direction={'row'} py={1}>
                  <Typography variant="h6" fontWeight={700}>{'About'}</Typography>
                  {parse(event?.description)}
                </Grid2>
              }

              {
                event?.venue?.layout_image && event?.venue?.layout_image !== '' &&
                <Grid2 container direction={'column'} py={1}>
                  <Typography variant="h6" fontWeight={700}>{'Venue Layout'}</Typography>

                  <img src={event?.venue?.layout_image} width="300" height="300" />
                </Grid2>
              }

              {
                event?.terms && event?.terms.length >0 &&
                <Grid2 container direction={'row'}  py={1}>
                  <Typography variant="h6" fontWeight={700}>{'Terms and Conditions'}</Typography>
                  <List>
                    {
                      event?.terms?.map((term, i) => {
                        return (
                          <ListItem disablePadding>
                            <ListItemIcon sx={{minWidth:0}}>
                              <ArrowRightOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {term}
                            </ListItemText>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </Grid2>
              }

              {/* <VenueInformation event={event} /> */}
            </Grid2>
          </Grid2>

        </Grid2>
    </Grid2>
  );
}
