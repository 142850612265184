import * as React  from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Typography, Grid2, Box, Button, TextField } from '@mui/material';
import Iconify from '../../../../../components/Iconify';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialMediaLinks, getSupportDetails } from '../../../../../store/reducers/config_reducer';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { saveQuery } from '../../../../../data/network/contact';
import { useCookies } from 'react-cookie';
import { getLoadingKey, getLoadingStatus, setLoadingKey } from '../../../../../store/reducers/layout_reducer';
import Loading1 from '../../../../../components/Loader/loader_1/Loading1';

export default function Contact() {

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);


  const isLoading = useSelector((state) => getLoadingStatus(state));
  const loadingKey = useSelector((state) => getLoadingKey(state));

  const supportDetails = useSelector((state) => getSupportDetails(state));
  const socialMediaLinks = useSelector((state) => getSocialMediaLinks(state));

  const [name, setName] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handleNameChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setName(value);
  }

  const handleEmailChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setEmail(value);
  }


  const handleMobileChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setMobile(value);
  }


  const handleTitleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setTitle(value);
  }


  const handleDescriptionChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setDescription(value);
  }


  // const handleSubmit = (event) => {
  //   // alert('A form was submitted: ' + this.state.name + ' // ' + this.state.email);
  //   event.preventDefault();
  // }

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(),
    mobile: Yup.number().required(),
    query: Yup.string().required(),
    email: Yup.string().required().email('Email must be a valid email address'),
 
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email:'',
      mobile: '',
      query: '',
    },
    validationSchema: ContactSchema,
    onSubmit: () => {

    },
  });

  const handleContactSubmit = (event) =>{
    event.stopPropagation();
    dispatch(setLoadingKey({key : 'btn-contact-us-submit'}))
    saveQuery(dispatch, cookies, {
        name: formik.values.name,
        mobile: formik.values.mobile,
        email: formik.values.email,
        query: formik.values.query
    });
    resetForm();
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;


  return (
    <Grid2 className={'section section-faqs'} container direction={'column'} justifyContent={'center'} alignItems={'center'} padding={4} style={{width:'100%'}}> 
        {/* <Typography variant="h4" className={'section-title'}>Contact Us</Typography>
        <Box height={16} /> */}

        <div class="form">
          <div class="contact-info">
            <h3 class="title">Let's get in touch</h3>
            <p class="text">
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
              dolorum adipisci recusandae praesentium dicta! */}
            </p>

            <div class="info">
              {/* <div class="information">
                <i class="fas fa-map-marker-alt"></i> 

                <p>92 Cherry Drive Uniondale, NY 11553</p>
              </div> */}
              <div class="information">
                <i class="fas fa-envelope"></i> 
                <p>{supportDetails?.support_email}</p>
              </div>
              <div class="information">
                <i class="fas fa-phone"></i>
                <p>{supportDetails?.support_number}</p>
              </div>
            </div>

            <div class="social-media">
              <p>Connect with us :</p>
              <div class="social-icons"  >
                {
                  // socialMediaLinks?.facebook && 
                  <a href="#">
                    <FacebookIcon />
                    {/* <Iconify icon={'fa:fa-facebook'} /> */}
                    {/* <i class="fab fa-facebook-f"></i> */}
                  </a>
                }

                {
                  socialMediaLinks && socialMediaLinks.instagram && 
                  <a href="#">
                    <InstagramIcon />
                    {/* <Iconify icon={'fa:fa-instagram'} /> */}
                    {/* <i class="fab fa-facebook-f"></i> */}
                  </a>
                }

                {
                  socialMediaLinks && socialMediaLinks.twitter && 
                  <a href="#">
                    <TwitterIcon />
                    {/* <Iconify icon={'fa:fa-twitter'} /> */}
                    {/* <i class="fab fa-facebook-f"></i> */}
                  </a>
                }
              </div>
            </div>
          </div>

          <div class="contact-form">
            <span class="circle one"></span>
            <span class="circle two"></span>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <form action="index.html" autocomplete="off">
                  <h3 class="title">Contact Us</h3>
                  <div class="input-container">
                    <TextField
                      type="text"
                      name="name"
                      variant="filled"
                      className="contact-us-input"
                      placeholder='Name'
                      sx={{width:'100%', height:'100%'}}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div class="input-container">
                    <TextField
                      type="email"
                      name="email"
                      className="contact-us-input"
                      placeholder='Email'
                      variant="filled"
                      sx={{width:'100%'}}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </div>
                  <div class="input-container">
                    <TextField
                      type="number"
                      name="mobile"
                      className="contact-us-input"
                      placeholder='Mobile'
                      sx={{width:'100%'}}
                      variant="filled"
                      {...getFieldProps('mobile')}
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                    />
                  </div>
                  <div class="input-container">
                    <TextField
                      type="text"
                      name="query"
                      className="contact-us-input textarea"
                      variant="filled"
                      multiline
                      minRows={4}
                      placeholder='Enter your message here'
                      sx={{width:'100%'}}
                      {...getFieldProps('query')}
                      error={Boolean(touched.query && errors.query)}
                      helperText={touched.query && errors.query}
                    />
                  </div>
                  <center>
                    <Button sx={{height: 50, width: 250, borderRadius: 10}} className={'btn-glass'} onClick={handleContactSubmit}>
                      {
                        isLoading && loadingKey == 'btn-contact-us-submit'
                        ? 'Saving...' : 'Send'
                      }
                      
                    </Button>
                  </center>
                </form>
                </Form>
            </FormikProvider>
          </div>
        </div>
        <Box height={16} />

    </Grid2>

  );
}
