import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// @mui
import { Card, Link, Container, Typography, Grid2, Box, Button, Autocomplete, TextField, InputAdornment, FormControl, InputLabel, FilledInput, OutlinedInput, Stack, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { wrap } from 'framer-motion';
import { MOCK_SETTINGS_EVENT_ABOUT_YOU, MOCK_SETTINGS_EVENT_REQUIRED_TICKET_DETAILS, MOCK_SETTINGS_EVENT_RESTRICTION_DETAILS } from '../../../data/local/settings';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { getLoginStatus } from '../../../store/reducers/user_reducer';
import { getLoadingKey, getLoadingStatus, setLoadingKey, setLoadingStatus, setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { addListing, fetchListingForEvent } from '../../../data/network/listing';
import Loader2 from '../../../components/Loader/loader_2/Loader2';
import { getSaveListingResponse } from '../../../store/reducers/listing_reducer';
import { logEvent } from '../../../utils/ga';

export default function TicketListingForm({event, next}) {

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [ticketType, setTicketType] = useState('printed-ticket');
  const [userCategoryType, setUserCategoryType] = useState();
  const [ticketCount, setTicketCount] = useState();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const AboutYouOptions = MOCK_SETTINGS_EVENT_ABOUT_YOU;
  const RequiredTicketDetailsOptions= MOCK_SETTINGS_EVENT_REQUIRED_TICKET_DETAILS;
  const RestrictionDetailsOptions= MOCK_SETTINGS_EVENT_RESTRICTION_DETAILS  ;

  const isLoggedIn = useSelector(state => getLoginStatus(state));

  const isLoading = useSelector(state => getLoadingStatus(state));
  const loadingKey = useSelector(state => getLoadingKey(state));

  const saveListingResponse = useSelector(state => getSaveListingResponse(state));

  const handleListingSubmit = (e) => {
      e.stopPropagation();

      if (isLoggedIn){
        handleListingSave();

      }else{
          // show login modal
          dispatch(setLoginModalVisibilityStatus({'status' : true}));
      }
  }

  const handleTicketTypeSelect = (event, type) => {
    setTicketType(type);
  }
  
  const handleUserCategoryTypeSelect = (event, type) => {
    setUserCategoryType(type);
  }

  const handleTicketCountSelect = (event, count) => {
    setTicketCount(count);
  }

//   const ListingSchema = Yup.object().shape({
//     ticket_type: Yup.string() ,
//     lastName: Yup.string() ,
//     email: Yup.string().email('Email must be a valid email address'),
 
//   });

  const formik = useFormik({
    initialValues: {
      ticket_type: 'printed-ticket',
      ticket_count: 1,
      seating_details: '',
      description: '',
      user_category: 'neither-of-these',
      face_value: '',
      listing_value : '',
      section: ''
    },
    // validationSchema: ProfileSchema,
    onSubmit: () => {

    },
  });

  const handleListingSave = () =>{

    logEvent({'category': 'event_listing_submit', 'action': event?.slug, 'label': event?.display_name});

    dispatch(setLoadingKey({key: 'btn-listing-form-submit'}));
    dispatch(setLoadingStatus({status: true}));

    // if (ticketType === undefined || ticketType === null){
    //     return;
    // }

    if (userCategoryType === undefined || userCategoryType === null){
        return;
    }

    if (ticketCount === undefined || ticketCount === null){
        return;
    }

    setIsFormSubmitting(true);

    addListing(dispatch, cookies, {
        event_id: event?.slug,
        ticket_type_id: ticketType,
        split_type_id: "any",
        user_category_id: userCategoryType,
        ticket_count : (ticketCount>5 ? formik.values.ticket_count : ticketCount),
        face_value: formik.values.face_value,
        listing_value : formik.values.listing_value,
        description: formik.values.description,
        section: formik.values.section
    });

  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;


  useEffect(() => {
    if (isFormSubmitting && saveListingResponse?.success){
        next(true);
        fetchListingForEvent(dispatch, cookies, event?.slug);
        // navigate('/success');
    }
  },[saveListingResponse]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'} sx={{ padding:1, width:'100%'}}>

                    {/* <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'row'} columnSpacing={1}>
                            <InfoIcon />
                            <Typography sx={{maxWidth:'90%'}} variant="body1">{`You can edit your ticket details and price later`}</Typography>
                        </Grid2>
                    </Card> */}

                    {/* <Box height={16} />

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Choose Ticket Type`}</Typography>
                    </Grid2>

                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'row'} flexWrap={wrap} columnSpacing={1} rowSpacing={1}>
                            {
                                event && event.ticket_options && event.ticket_options.map((option) => {
                                    return <Grid2 container size={4}>
                                        <Card className={ticketType === option.slug ? 'card-selected' : ''} sx={{padding:2}} flex={1} onClick={(e) => handleTicketTypeSelect(e, option.slug)}>
                                        <Typography variant="h6">{option.title}</Typography>
                                        <Typography variant="body1" fontSize={10}>{option.description}</Typography>
                                    </Card></Grid2>
                                })
                            }
                        </Grid2>
                    </Card>

                    <Box height={32} /> */}

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Enter Number of Tickets`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11} sx={{maxWidth:'90%'}}>
                                    <Typography variant="body1"> {`If seat numbers are specified on your tickets, all tickets must be consecutive. For non-consecutive tickets, you must create separate listings.`}</Typography>
                                </Grid2>
                            </Grid2>
                            
                            <Grid2 container direction={'row'} flexWrap={wrap} columnSpacing={1} rowSpacing={1} padding={1}>
                                {
                                    ['1','2','3','4','5'].map((e) => {

                                        return (
                                            <Grid2 container  size={{lg:2, xl:2, md:3, sm:4, xs:4}}  direction={'row'} justifyContent='center' alignItems={'center'}>
                                                <Card className={ticketCount === e ? 'card-selected' : ''} onClick={(x) => handleTicketCountSelect(x, e)} sx={{cursor:'pointer', padding:2, width:'100%' }}>
                                                    <Typography variant="h6" textAlign={'center'}>{e}</Typography>
                                                </Card>
                                            </Grid2>
                                            
                                        )

                                    })
                                                                        
                                }

                                {
                                    <Grid2 container  size={{lg:2, xl:2, md:3, sm:4, xs:4}}  direction={'row'} justifyContent='center' alignItems={'center'}>
                                        <Card className={ticketCount > 5 ? 'card-selected' : ''} onClick={(x) => handleTicketCountSelect(x, 6)} sx={{cursor:'pointer', padding:2, width:'100%' }}>
                                            <Typography variant="h6" textAlign={'center'}>{'6+'}</Typography>
                                        </Card>
                                    </Grid2>
                                }

                                {
                                    (ticketCount > 5) && <TextField 
                                        name='ticket_count'
                                        placeholder='Enter number of tickets' 
                                        onChanged={(e) => {
                                            console.log(e);
                                            // console.log(v);
                                            // setTicketCount(e)
                                        }} 
                                        {...getFieldProps('ticket_count')}
                                        error={Boolean(touched.ticket_count && errors.ticket_count)}
                                        helperText={touched.ticket_count && errors.ticket_count}
                                    />
                                }
                            </Grid2>
                        </Grid2>
                    </Card>

                    <Box height={32} />

                    {/* <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Choose Split Types`}</Typography>
                    </Grid2>
                    */}
                    {/* <Card sx={{padding:2}}>
                        <Grid2 container direction={'row'} flexWrap={wrap} columnSpacing={1} rowSpacing={1}>
                            {
                                event && event.split_types && event.split_types.map((option) => {
                                    return <Grid2 container size={{lg:4, xl:4, md:6, sm:12, xs:12}} direction={'row'} justifyContent='center' alignItems={'center'}>
                                        <Card sx={{padding:2,  minHeight:'100px', width:'100%'}}>
                                            <Typography variant="body1" fontWeight={700}>{option.title}</Typography>
                                            <Typography variant="body1" fontSize={10} py={1}>{option.description}</Typography>
                                        </Card>
                                    </Grid2>
                                })
                            }
                        </Grid2>
                    </Card>

                    <Box height={32} />
        */}

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Enter Seating Details`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11} sx={{maxWidth:'90%'}}>
                                    <Typography variant="body1" > {`You are required to provide section, row and seat information if this information is available to you at the time of listing. If you do not have all of this information at present, you may list your tickets, but you must update your listing once you have this information. Listings can be updated using My Account.`}</Typography>
                                </Grid2>
                            </Grid2>

                            <Grid2 container  direction={'column'} sx={{width:'100%'}}>

                                <Grid2 container direction={'column'} padding={1} rowSpacing={1} sx={{width:'100%'}}>

                                    {/* <Typography variant="body1" fontWeight={700}>Section</Typography> */}

                                    <TextField
                                        id="seating-detail-section"
                                        multiline
                                        minRows={4}
                                        // sx={{ width: '60ch' }}
                                        // type="select"
                                        // slotProps={{
                                        //     select: {

                                        //     },
                                        // }}
                                        {...getFieldProps('section')}
                                        error={Boolean(touched.section && errors.section)}
                                        helperText={touched.section && errors.section}
                                        
                                    />
                                </Grid2>

                                {/* <Grid2 container direction={'column'} padding={1} rowSpacing={1}>

                                    <Typography variant="body1" fontWeight={700}>Row</Typography>

                                    <TextField
                                        id="seating-detail-row"
                                        sx={{ width: '60ch' }}
                                        type="text"
                                        slotProps={{
                                            textfield: {
                                            },
                                        }}
                                />
                                </Grid2> */}

                                {/* <Grid2 container direction={'column'} padding={1} rowSpacing={1}>

                                    <Typography variant="body1" fontWeight={700}>Seat Number</Typography>
                                    <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} columnSpacing={1}>
                                        <Grid2 container direction={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                            <Typography variant="body2" fontWeight={700}>
                                                Seat From
                                            </Typography>

                                            <TextField
                                                // label="With normal TextField"
                                                id="seating-detail-seat-number-start"
                                                sx={{ width: '15ch' }}
                                                type="number"
                                                slotProps={{
                                                    textfield: {
                                                    },
                                                    input: {
                                                    },
                                                }}
                                            />
                                            <Typography variant="body2">
                                                First seat
                                            </Typography>

                                        </Grid2>

                                        <Typography variant="body1">
                                            to
                                        </Typography>

                                        <Grid2 container direction={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                            <Typography variant="body2" fontWeight={700}>
                                                Seat To
                                            </Typography>

                                            <TextField
                                                // label="With normal TextField"
                                                id="seating-detail-seat-number-end"
                                                sx={{ width: '15ch' }}
                                                type="number"
                                                slotProps={{
                                                    textfield: {
                                                    },
                                                    input: {
                                                    },
                                                }}
                                            />
                                            
                                            <Typography variant="body2">
                                                Last seat
                                            </Typography>

                                        </Grid2>
                                    </Grid2>
                                </Grid2> */}

                                {/* <Box m={2} /> */}

                                {/* <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">If you are unable to provide seating information please select a reason why:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        // value={''}
                                        onChange={() => {

                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="The primary site has not provided me with this information" />
                                        <FormControlLabel value="2" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl> */}


                            </Grid2>
                        </Grid2>
                    </Card>

                    <Box height={32} />

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Enter Face Value`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11} sx={{maxWidth:'90%'}}>
                                    <Typography variant="body1"> {`Face value is the price printed on the ticket, excluding any booking fees.`}</Typography>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 container >

                            <TextField
                                // label="With normal TextField"
                                id="face-value-amount"
                                sx={{ m: 1 }}
                                type="number"
                                slotProps={{
                                    textfield: {
                                    },
                                    input: {
                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">per ticket</InputAdornment>,
                                    },
                                }}
                                {...getFieldProps('face_value')}
                                error={Boolean(touched.face_value && errors.face_value)}
                                helperText={touched.face_value && errors.face_value}
                            />
                        </Grid2>
                    </Card>


                    <Box height={32} />

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Enter Listing Value`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            {/* <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11} sx={{maxWidth:'90%'}}>
                                    <Typography variant="body1"> {`Face value is the price printed on the ticket, excluding any booking fees.`}</Typography>
                                </Grid2>
                            </Grid2> */}
                        </Grid2>
                        <Grid2 container sx={{width:'100%'}}>

                            <TextField
                                // label="With normal TextField"
                                id="listing_price"
                                sx={{ m: 1}}
                                type="number"
                                slotProps={{
                                    textfield: {
                                    },
                                    input: {
                                        startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">per ticket</InputAdornment>,
                                    },
                                }}
                                {...getFieldProps('listing_value')}
                                error={Boolean(touched.listing_value && errors.listing_value)}
                                helperText={touched.listing_value && errors.listing_value}
                            />
                        </Grid2>
                    </Card>
                    <Box height={32} />


                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`About You`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11} sx={{maxWidth:'90%'}}>
                                    <Typography variant="body1"> {`If you work for tickit, or are the organiser of this event, you are required by section 90(6) of the Consumer Rights Act to select it below:`}</Typography>
                                </Grid2>
                            </Grid2>

                            <Grid2 container direction={'row'} flexWrap={wrap} columnSpacing={1} rowSpacing={1} padding={1} >
                                {
                                    AboutYouOptions && AboutYouOptions.map((option) => {
                                        return <Grid2 container size={{lg:4, xl:4, md:6, sm:12, xs:12}} direction={'row'} justifyContent='center' alignItems={'center'}>
                                            <Card className={userCategoryType === option.slug ? 'card-selected' : ''} flex={1} onClick={(e) => handleUserCategoryTypeSelect(e, option.slug)} sx={{padding:2,  minHeight:'100px', width:'100%'}}>
                                                <Typography variant="body1" fontWeight={700}>{option.title}</Typography>
                                                <Typography variant="body1" fontSize={10} py={1}>{option.description}</Typography>
                                            </Card>
                                        </Grid2>
                                    })
                                }
                            </Grid2>
                        </Grid2>
                    </Card>

                    <Box height={32} />

                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Other Information`}</Typography>
                    </Grid2>

                    <Card sx={{padding:2, width:'100%'}}>
                        <Grid2 container direction={'column'}>
                            {/* <Grid2 container direction={'row'} columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11}>
                                    <Typography variant=""> {`Add other information related to your tickets here.`}</Typography>
                                </Grid2>
                            </Grid2> */}

                            <Grid2 container  direction={'column'}>

                                <Grid2 container direction={'column'} padding={1} rowSpacing={1}>

                                    <TextField
                                        id="description"
                                        multiline
                                        rows={6}
                                        {...getFieldProps('description')}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Card>

        {/* 
                    <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Select Restrictions on Use`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'}  columnSpacing={1}>
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11}>
                                    <Typography variant=""> {`If any of the following conditions apply to your tickets, please select them from the list below. If there is a restriction on the use of your ticket not shown here, please stop listing and contact us.`}</Typography>
                                </Grid2>
                            </Grid2>

                            <Grid2 container direction={'column'} >

                                <Grid2 container>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                defaultChecked 
                                                checked={false}
                                                onChange={() => {}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } 
                                        label={'No Restrictions'} 
                                    />
                                </Grid2>

                                <Typography variant="body1"> Or </Typography>

                                <Grid2 container direction={'row'} flexWrap={'wrap'} justifyContent={'flex-start'} columnSpacing={1} >
                                    {
                                        RestrictionDetailsOptions && RestrictionDetailsOptions.map((e) => {
                                            return <FormControlLabel 
                                                control={
                                                    <Checkbox 
                                                        defaultChecked 
                                                        checked={false}
                                                        onChange={() => {}}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } 
                                                label={e.title} 
                                            />
                                        }) 
                                    }
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Card> */}

                    <Box height={32} />

                    {/* <Grid2 container direction={'column'}>
                        <Typography variant="h5">{`Select Required Ticket Details`}</Typography>
                    </Grid2>
                    
                    <Card sx={{padding:2}}>
                        <Grid2 container direction={'column'}>
                            <Grid2 container direction={'row'} columnSpacing={1} >
                                <Grid2 container flexWrap={'wrap'}>
                                    <InfoIcon />
                                </Grid2>
                                <Grid2 container flexWrap={'wrap'} size={11}>
                                    <Typography variant=""> {`If any of the following conditions apply to your tickets, you must select the corresponding options below.`}</Typography>
                                </Grid2>
                            </Grid2>
                            
                            <Grid2 container direction={'row'} flexWrap={'wrap'} justifyContent={'flex-start'} columnSpacing={1} >
                                {
                                    RequiredTicketDetailsOptions && RequiredTicketDetailsOptions.map((e) => {
                                        return <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    defaultChecked 
                                                    checked={false}
                                                    onChange={() => {}}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            } 
                                            label={e.title} 
                                        />
                                    }) 
                                }
                            </Grid2>
                        </Grid2>
                    </Card>

                    <Box height={32} /> */}

                    <Grid2 container sx={{width:'100%'}} justifyContent={'center'}>
                        {
                            <Button className='btn btn-primary' variant="contained" onClick={handleListingSubmit}>
                                {
                                    isLoading && loadingKey === 'btn-listing-form-submit'
                                    ? <Loader2 />
                                    : 'Continue'
                                }
                            </Button>
                        }
                    </Grid2>


                    <Box height={32} />

            </Grid2>
        </Form>
    </FormikProvider>
  );
}
