import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, Button, Autocomplete, TextField } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

import './Join.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { MOCK_EVENT_DATA } from '../../data/local/mock';
import { fetchEventList } from '../../data/network/event';
import { getEventList } from '../../store/reducers/event_reducer';

export default function Join() {

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const events = useSelector(state => getEventList(state));
//   const events = MOCK_EVENT_DATA;

  const handleEventSelect = (event, value) => {
    navigate('/addtickets/event/'+value.slug);
  }

  useEffect(() => {
    fetchEventList(dispatch, cookies, 1, 100);
  },[])

  return (
    <Page title="Join">
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'header header-transparent'} container style={{ width:'100%'}}>
                <Header />
            </Grid2>
     
            <Grid2 container direction={'column'} alignItems={'center'} justifyContent={'flex-start'} sx={{width:'100%'}}>
                <Box height={32} />
                <Typography variant="h3">
                    Select a event
                </Typography>
                <Typography variant="subtitle1" px={4} textAlign={'center'}>
                    tickit is the India's leading experience provider for live events
                </Typography>
                <Box height={32} />

                <Grid2 container justifyContent={'center'} alignItems={'center'} sx={{width:'100%'}}>
                    <Autocomplete
                        disablePortal
                        // freeSolo
                        onChange={handleEventSelect}
                        options={events}
                        getOptionLabel={(option) => option.display_name}
                        sx={{ width: '60%' }}
                        renderInput={(params) => <TextField {...params} label="Search your event" />}
                    />
                </Grid2>
                <Box height={32} />
                

                <Box height={32} />

            </Grid2>

            <Footer />
        </Grid2>
    </Page>
  );
}
