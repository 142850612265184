import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Card, Grid2, Typography } from '@mui/material';

// react redux
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
// components

import './EventList.css';

export default function EventListCard({event, row}) { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  return (    
    <Card className='event-list-card' key={row} sx={{padding:2, display:'inline-block'}} onClick={() => {
        navigate(`/event/${event.id}`);
    }}>
        <Grid2 container size={12} direction={'column'} justifyContent={'center'} alignItems={'flex-start'} sx={{display:'inline-block'}}>

            <Grid2 container direction={'row'} justifyContent={'space-between'} alignItems={'center'} columnSpacing={2}>
                <Grid2 container direction={'row'} >
                    <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'center'} >
                        <Typography variant="">{event.date}</Typography>
                        <Typography variant="">{event.start_time}</Typography>
                    </Grid2>
                    <Grid2 container direction={'column'} >
                        <hr />
                    </Grid2>
                    <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'center'} >
                        <Typography variant="">{event.title}</Typography>
                        <Typography variant="">{event.venue.name}</Typography>
                        <Typography variant="">{`${event.venue.city}, ${event.venue.country}`}</Typography>
                    </Grid2>
                </Grid2>

                <Grid2 container direction={'column'} >

                    <Button variant="outlined" onClick={() => {
                        navigate(`/event/${event.id}`);
                    }}>See tickets</Button>
                    
                </Grid2>

            </Grid2>

        </Grid2>
    </Card>
  );
}
