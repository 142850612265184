import { Copyright, CopyrightRounded } from "@mui/icons-material";
import { Box, Button, Grid, Grid2, Typography } from "@mui/material";
import React from "react";
// import { FOOTER_LINKS } from "../constants/app_constants";
// import {
//   Box,
//   Column,
//   FooterLink,
//   Links,
// } from "./HeaderStyles";
import Iconify from "../Iconify";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getLoginStatus } from "../../store/reducers/user_reducer";
import { useNavigate } from "react-router-dom";
import AccountIcon from "@mui/icons-material/AccountCircleOutlined"

import './Header.css';
import { setLoginModalVisibilityStatus } from "../../store/reducers/layout_reducer";

export default function HeaderDark () {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleLoginClick = () => {
        // navigate('/login');
        dispatch(setLoginModalVisibilityStatus({status: true}));

    }

    const handleMyAccountClick = () => {
        navigate('/profile');
    }

    return (

        <Box width={'100%'}>
            <Grid2 item container direction={'row'} justifyContent="space-between" px={1} alignItems={'center'} sx={{width:'100%'}}>
                <Grid2 item container mt={2} justifyContent="flex-start" size={{xs:6, sm:4, md:3, lg:3, xl:2}}>
                    <a href='/'>
                        <img src='../../static/logo-white.png' alt='Tickit' width={96} />
                    </a>
                </Grid2>
                
                <Grid2 item container mt={2} justifyContent="flex-end" color={'#fff'} size={{xs:6, sm:4, md:3, lg:3, xl:2}}>
                    {
                        isLoggedIn ? 
                        <AccountIcon color="white" onClick={handleMyAccountClick} style={{fontSize:'48px'}} />
                        // <Button className={'btn-glass'} onClick={handleMyAccountClick} variant="contained" style={{height: '40px', width: '160px'}}>My Account</Button>
                        : <Button className={'btn-glass'} onClick={handleLoginClick} variant="outlined" style={{height: '40px', width: '80px'}}>Login</Button>
                    }
                </Grid2>
                
            </Grid2>
        </Box>
  );
}