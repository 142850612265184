import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Grid2, Typography } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
// components
import Page from '../../components/Page';

import './Explore.css';
import Header from '../../components/Header/Header';
import { FILTER_PARAMS, MOCK_EVENT, MOCK_EVENT_DATA } from '../../data/local/mock';
import EventList from '../../components/EventList/EventList';
import Footer from '../../components/Footer/Footer';
import FilterContainer from '../../components/FilterContainer/FilterContainer';
import EventCard from '../../components/Cards/EventCard/EventCard';
import HeaderDark from '../../components/Header/HeaderDark';
import { fetchEventList } from '../../data/network/event';
import { getEventList, getEventPagination } from '../../store/reducers/event_reducer';
import { useEffect, useState } from 'react';
import { getLoadingKey, setLoadingKey, setLoadingStatus } from '../../store/reducers/layout_reducer';
import { getLoginStatus } from '../../store/reducers/user_reducer';
import Loader2 from '../../components/Loader/loader_2/Loader2';
import ReactGA from 'react-ga4';

export default function Explore() { 

  ReactGA.send({ hitType: "pageview", page: "/explore", title: "Explore" });

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [loadingEvents, setLoadingEvents] = useState(false);
  const [seeMoreEvents, setSeeMoreEvents] = useState(false);

  // const events = [MOCK_EVENT, MOCK_EVENT,MOCK_EVENT, MOCK_EVENT,MOCK_EVENT];

  const events = useSelector(state => getEventList(state));
  const eventsPagination = useSelector(state => getEventPagination(state));

  const isLoading = useSelector(state => getLoginStatus(state));

  const fetchEvents = () => {
    fetchEventList(dispatch, cookies);
  }

  const filter_params = FILTER_PARAMS;

  const handleNextPage = () => {
    setSeeMoreEvents(true);
    fetchEventList(dispatch, cookies, eventsPagination?.page+1);
  }

  useEffect(() => {
    setLoadingEvents(false);

  },[events])

  useEffect(() => {
    setLoadingEvents(true);
    fetchEvents();
  },[]);

  return (
    <Page title="Explore">
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 container className={'header'} sx={{width:'100%'}}>
                <Header />
            </Grid2>
            
            <Box padding={2} sx={{width:'100%'}}>
              <Typography py={1} variant="h5" className={'section-title'}>All Events</Typography>
              <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid2 container direction={'row'} justifyContent={'flex-start'} py={1} alignItems={'flex-start'} rowSpacing={4} columnSpacing={4} sx={{width:'100%'}}>
                    {/* <EventList events={events} /> */}

                    {
                      !loadingEvents && events && events.map((event, index) => {
                          return <Grid2 key={index} container size={{xl:3, lg:3, md: 4, sm:6, xs:6}}>
                            <EventCard
                                key={index}
                                event={event}
                            />
                          </Grid2>
                      })
                    }

                    {
                      loadingEvents && <Loader2 />
                    }
                    
                </Grid2>

                {
                  (eventsPagination?.page < eventsPagination?.total_pages)
                  && <Grid2 container justifyContent={'center'} py={4}>
                      <Button className={'btn-primary'} variant={'filled'} onClick={handleNextPage}>
                        {
                          seeMoreEvents
                          ? 'Loading...'
                          : 'See more'
                        }
                      </Button>
                  </Grid2>

                }

              </Grid2>
              
            </Box>

            <Box margin={4} />

            <Footer />
        </Grid2>
    </Page>
  );
}
