import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './Footer.css';
import { Box, Button, Grid2, InputAdornment, TextField, Typography } from "@mui/material";
import { subscribeNewsletter } from "../../data/network/newsletter";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import { useCookies } from "react-cookie";
import { getLoadingKey, getLoadingStatus, setLoadingKey } from "../../store/reducers/layout_reducer";
import { getFooterLinks } from "../../store/reducers/config_reducer";

export default function Footer () {

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
    const navigate = useNavigate();
    const isLoading = useSelector(state => getLoadingStatus(state));
    const loadingKey = useSelector(state => getLoadingKey(state));

    const footerLinks = useSelector(state => getFooterLinks(state));

    const handleMyAccountClick = () => {
        navigate('/my-account');
    }

    const NewsletterSubscribeSchema = Yup.object().shape({
      email: Yup.string().required().email('Email must be a valid email address'),
    });
  
    const formik = useFormik({
      initialValues: {
        email:'',
      },
      validationSchema: NewsletterSubscribeSchema,
      onSubmit: () => {
  
      },
    });
  
    const handleNewsletterSubscribe = (event) =>{
      event.stopPropagation();
      dispatch(setLoadingKey({key : 'btn-newsletter-subscribe'}))
      console.log(formik.values.email);
      if (formik.values.email != ''){
        subscribeNewsletter(dispatch, cookies, {
            email: formik.values.email
        });
        resetForm();
      }
    }
  
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, resetForm } = formik;
  

    return (

<footer className="footer-section">
    <Grid2 container className="footer-top" sx={{width:'100%'}}>
        <Grid2 container direction={'row'} px={1} sx={{width:'100%'}}>

          <Grid2 size={{xs:12, sm:6, md:6, lg:4, xl:4}} >
            <div className="widget company-intro-widget">
              <a href="/" className="site-logo">
                <img src="../../static/logo-white.png" alt="logo" sizes={64}/>
              </a>
              <Typography>Find nearby events and music festivals.</Typography>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p> */}
              <ul className="company-footer-contact-list">
                {/* <li><i className="fas fa-phone"></i>0123456789</li> */}
                {/* <li><i className="fas fa-clock"></i>Bengaluru, India</li>
                <li><i className="fas fa-clock"></i>Mon - Sat 8.00 - 18.00</li> */}
              </ul>
            </div>
          </Grid2>




          <Grid2 size={{xs:12, sm:6, md:6, lg:4, xl:4}} py={{xs:4, sm:4, md:4}} >
            <div className="widget course-links-widget">
              <h5 className="widget-title">Useful Links</h5>

              <ul className="courses-link-list">
                {
                  footerLinks?.map(e => {
                    return <li><a href={e.link}><i className="fas fa-long-arrow-alt-right"></i>{e.text}</a></li>
                  })
                }
                {/* <li><a href="/explore"><i className="fas fa-long-arrow-alt-right"></i>Explore events</a></li> */}
                {/* <li><a href="/explore"><i className="fas fa-long-arrow-alt-right"></i>Join Us</a></li> */}
                {/* <li><a href="#"><i className="fas fa-long-arrow-alt-right"></i>Feeding India Concert</a></li> */}
                {/* <li><a href="#"><i className="fas fa-long-arrow-alt-right"></i>PC Systems</a></li>
                <li><a href="#"><i className="fas fa-long-arrow-alt-right"></i>Ui Web Design</a></li>
                <li><a href="#"><i className="fas fa-long-arrow-alt-right"></i>Web Development</a></li>
                <li><a href="#"><i className="fas fa-long-arrow-alt-right"></i>Apps Development</a></li> */}
              </ul>
            </div>
          </Grid2>





          {/* <Grid2 size={{xs:12, sm:6, md:6, lg:3, xl:3}} >
            <div className="widget latest-news-widget">
              <h5 className="widget-title">lastest news</h5>
              <ul className="small-post-list">
                <li>
                  <div className="small-post-item">
                    <a href="#" className="post-date">July 18, 2018</a>
                    <h6 className="small-post-title"><a href="#">Lorem Ipsum is simply dummy text of the printing.</a></h6>
                  </div>
                </li>
                <li>
                  <div className="small-post-item">
                    <a href="#" className="post-date">July 28, 2018</a>
                    <h6 className="small-post-title"><a href="#">Lorem Ipsum is simply dummy text of the printing</a></h6>
                  </div>
                </li>
              </ul>
            </div>
          </Grid2> */}




          <Grid2 size={{xs:12, sm:6, md:6, lg:4, xl:4}} py={{xs:4, sm:4, md:4}} >
            <div className="widget newsletter-widget">
              <h5 className="widget-title">Newsletter</h5>
              <div className="footer-newsletter">
                <p>Sign Up to Our Newsletter to Get Latest Updates</p>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" className={'news-letter-form'} noValidate onSubmit={handleSubmit}>
                  
                    {/* <input type="email" name="news-email" id="news-email" placeholder="Your email address" style={{maxWidth:'400px'}} /> */}
                    <TextField

                      type="email"
                      name="newsletter-email"
                      id="newsletter-email"
                      placeholder="Your email address"
                      variant="filled"
                      sx={{maxWidth:'400px', backgroundColor:'#fff'}}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      slotProps={{
                        textfield: {
                        },
                        // input: {
                            // startAdornment: <InputAdornment position="start">Rs.</InputAdornment>,
                            // endAdornment: <InputAdornment position="end">
                            //   <Button className={'btn btn-primary'} sx={{borderRadius:2}} onClick={handleNewsletterSubscribe}>
                            //     {
                            //       isLoading && loadingKey == 'btn-newsletter-subscribe'
                            //       ? 'Saving...' : 'Subscribe'
                            //     }
                                
                            //   </Button>
                            // </InputAdornment>,
                        // },
                      }}

                    />
                    <Box margin={1} />
                    <Button className={'btn btn-glass'} onClick={handleNewsletterSubscribe}>
                        {
                          isLoading && loadingKey == 'btn-newsletter-subscribe'
                          ? 'Saving...' : 'Subscribe'
                        }
                        
                      </Button>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </Grid2>

      </Grid2> 
    </Grid2>
    <Grid2 className="footer-bottom">
      <Grid2 container direction={'row'} justifyContent={'space-between'} alignContent={'center'} px={1}>
        <Grid2 className="col-md-6 col-sm-6 text-sm-left text-center">
          <span className="copy-right-text">© 2024 <a href="#">STFUP Events LLP</a> All Rights Reserved.</span>
        </Grid2>
        <Grid2 container className="col-md-6 col-sm-6">
          <ul className="terms-privacy d-flex justify-content-sm-end justify-content-center">
            <li><a href="#">Terms & Conditions</a></li>
            {/* <li><a href="#">Privacy Policy</a></li> */}
          </ul>
        </Grid2>
      </Grid2>
    </Grid2>
  </footer>
);
}