import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { setUser } from '../../store/reducers/user_reducer';
import axios from '../../utils/axios-configure';
import {  API_FOLLOW_ARTIST, API_GET_ARTIST_DETAILS, API_GET_ARTIST_LIST } from "../endpoints";
import {getAccessTokenFromCookie} from './session'
import { SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { PROFILE_UPDATE } from '../../constants/message_constants';
import { setArtistData, setArtistDetail, setFeaturedArtistData, updateArtistFollowStatus } from '../../store/reducers/artist_reducer';

export const fetchArtistList = (dispatch, cookies, page=1, count=25) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies);
    axios({
        method: "get",
        url: `${API_GET_ARTIST_LIST}?page=${page}&count=${count}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            console.log(response.data);
            dispatch(setArtistData({data: response.data.data, pagination: response.data.pagination}));
        }else{
            dispatch(setArtistData({data: [], pagination: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setArtistData({data: [], pagination: {}}));
        dispatch(setLoadingStatus({status: false}));  
    });

}


export const fetchFeaturedArtistList = (dispatch, cookies, page=1, count=4) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "get",
        url: `${API_GET_ARTIST_LIST}?page=${page}&count=${count}&is_featured=1`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(setFeaturedArtistData({data: response.data.data, pagination: response.data.pagination}));
        }else{
            dispatch(setFeaturedArtistData({data: [], pagination: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setFeaturedArtistData({data: [], pagination: {}}));
        dispatch(setLoadingStatus({status: false}));  
    });

}


export const fetchArtistDetail = (dispatch, cookies, artistId) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    axios({
        method: "get",
        url: `${API_GET_ARTIST_DETAILS}?artist_id=${artistId}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            console.log(response.data);
            dispatch(setArtistDetail({data: response.data.data}));
        }else{
            dispatch(setArtistDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        dispatch(setArtistDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });

}

export const followArtist = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    
    axios({
        method: "post",
        url: `${API_FOLLOW_ARTIST}`,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
        console.log(response.data);
        // handle success
        if(response.status === 200 && response.data.success){
            dispatch(updateArtistFollowStatus({artist_id: data.artist_id, status: data.follow}))
        }else{
            // dispatch(setEventDetail({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((err) => {
        // dispatch(setEventDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
    });

}