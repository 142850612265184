import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { logoutUser } from '../store/reducers/user_reducer';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, activeIcon, inActiveIcon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: '#fff',
    fontWeight: 'fontWeightMedium',
    // bgColor: 'transparent',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: '#fff',
    fontWeight: 'fontWeightMedium',
  };

  const inActiveRootStyle = {
    // color: '#0f0f0f',
    fontWeight: 'fontWeightRegular',
    // bgColor: 'transparent',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const inActiveSubStyle = {
    // color: '#0f0f0f',
    fontWeight: 'fontWeightRegular',
  };


  // if (children) {
  //   return (
  //     <>
  //       <ListItemStyle
  //         onClick={handleOpen}
  //         sx={{
  //           ...(isActiveRoot && activeRootStyle),
  //         }}
  //       >
  //         <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
  //         <ListItemText disableTypography primary={title} />
  //         {info && info}
  //         <Iconify
  //           icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
  //           sx={{ width: 16, height: 16, ml: 1 }}
  //         />
  //       </ListItemStyle>

  //       <Collapse in={open} timeout="auto" unmountOnExit>
  //         <List component="div" disablePadding>
  //           {children.map((item) => {
  //             const { title, path } = item;
  //             const isActiveSub = active(path);

  //             return (
  //               <ListItemStyle
  //                 key={title}
  //                 component={RouterLink}
  //                 to={path}
  //                 sx={{
  //                   ...(isActiveSub && activeSubStyle),
  //                 }}
  //               >
  //                 <ListItemIconStyle>
  //                   <Box
  //                     component="span"
  //                     sx={{
  //                       width: 4,
  //                       height: 4,
  //                       display: 'flex',
  //                       borderRadius: '50%',
  //                       alignItems: 'center',
  //                       justifyContent: 'center',
  //                       bgcolor: 'text.disabled',
  //                       transition: (theme) => theme.transitions.create('transform'),
  //                       ...(isActiveSub && {
  //                         transform: 'scale(2)',
  //                         bgcolor: 'primary.main',
  //                       }),
  //                     }}
  //                   />
  //                 </ListItemIconStyle>
  //                 <ListItemText  disableTypography primary={title} />
  //               </ListItemStyle>
  //             );
  //           })}
  //         </List>
  //       </Collapse>
  //     </>
  //   );
  // }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot ? activeRootStyle : inActiveRootStyle),
      }}
    >
      <ListItemIconStyle>{isActiveRoot ? activeIcon : inActiveIcon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} sx={{
        ...(isActiveRoot ? activeSubStyle : inActiveSubStyle),
      }}/>
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const handleLogout = () => {
    removeCookie('mobile', { path: '/' });
    removeCookie('session', { path: '/' });
    removeCookie('access_token', { path: '/' });
    dispatch(logoutUser({data: {}})); 
  
  }
  return (
    <Box {...other} sx={{width:'100%'}}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.key} item={item} active={match} />
        ))}
        <Box sx={{ textAlign: 'start', width:'100%'  }}>
          <ListItemStyle
            // component={RouterLink}
            // to={'/logout'}
            onClick={handleLogout} 
            sx={{
              // ...(isActiveRoot && activeRootStyle),
            }}
          >
            <ListItemIconStyle> <Iconify icon="eva:log-out-outline" width={22} height={22} /></ListItemIconStyle>
            <ListItemText disableTypography primary="Logout" /> 
          </ListItemStyle>
        </Box>
      </List>
    </Box>
  );
}
