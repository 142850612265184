import { setUser } from '../../store/reducers/user_reducer';
import { setSendOTPResponse, setVerifyOTPResponse } from '../../store/reducers/auth_reducer';
import axios from '../../utils/axios-configure'; 
import { API_GET_SITE_CONFIG, API_SEND_OTP, API_VERIFY_OTP } from "../endpoints";
import { fetchUserDetails } from './user';
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { DEFAULT_SITE_CONFIG, SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { OTP_ERROR, OTP_SUCCESS } from '../../constants/message_constants';
import { getDeviceInfo } from '../../utils/device_helper';
import { setSiteConfigResponse } from '../../store/reducers/config_reducer';

export const fetchSiteConfig = async (dispatch, cookies) => {
    dispatch(setLoadingStatus({status: true}));

    let url = `${API_GET_SITE_CONFIG}`;

    // if(data.user_type){
    //     url = `${url}&user_type=${data.user_type}`
    // }

    // if(data.corporate_client_id){
    //     url = `${url}&corporate_client_id=${data.corporate_client_id}`
    // }
    // const deviceInfo = await getDeviceInfo();

    axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json",
            //  "X-Device-Info": `${deviceInfo}`
            },
    })
    .then((response)=> {

        if(response.status === 200 && response.data.success) {
            // dispatch(setSnackbarMessage({'message': SITE_CONFIG_SUCCESS.GET_SITE_CONFIG_SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));
            console.log(response.data.data);
            dispatch(setSiteConfigResponse({data:response.data.data}));
        }else{
            // dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));             
            dispatch(setSiteConfigResponse({data: DEFAULT_SITE_CONFIG}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((error)=> {
        console.log(error);
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSiteConfigResponse({data: DEFAULT_SITE_CONFIG}));
        // dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
        // dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}
