import * as React  from 'react';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Typography, Grid2, Box, Button, Stack } from '@mui/material';
import Background from '../../../../../assets/img/community.png';
import { COMMUNITY_SECTION_BANNER } from '../../../../../constants/file_constants';
import { getS3Url } from '../../../../../utils/aws';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function Community() {

  const navigate = useNavigate();

  const [isCommunityImageLoading, setIsCommunityImageLoading] = React.useState(false);
  const [communityImageUrl, setCommunityImageUrl] = React.useState();

  const communityImage = COMMUNITY_SECTION_BANNER;

  const fetchImageUrl = async (images) => {
    try{
      const _ImageWithUrl = await getS3Url(images);

      setCommunityImageUrl(_ImageWithUrl?.[0]);
      setIsCommunityImageLoading(false);
    }catch(e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    setIsCommunityImageLoading(true);
    fetchImageUrl([communityImage]);
  },[]);

  return (
    <Grid2 className={'section section-community'} container direction={'column'} style={{width:'100%'}}> 
      
      <Box className={'section'} sx={{minHeight:'400px', background: '#fff', position:'relative'}}>
        {
          !isCommunityImageLoading &&
          <img src={isMobile ? communityImageUrl?.image.file_url_mobile : communityImageUrl?.image.file_url_web} style={{width:'100%', height: '400px', opacity:0.8}} />
        }

        <BrowserView>
          <Grid2
            container 
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            rowSpacing={2}
            sx={{
              position:'absolute',top:'30%', right: '1%'
            }}
          >
            <Typography variant="h3">
              {communityImageUrl?.title}
            </Typography>
            <Button className={'btn-glass'} variant={'contained'} 
              sx={{height: 50, width: 250, borderRadius: 10}} 
              onClick={() => {
                navigate(communityImageUrl?.btn_link)
              }}>{communityImageUrl?.btn_text}
            </Button>
          </Grid2> 
          
        </BrowserView>

        <MobileView>

          <Grid2
            container 
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            rowSpacing={2}
            sx={{
              position:'absolute',top:'30%', width: '100%', textAlign: 'center'
            }}
          >
            <Typography variant="h2" sx={{color:'#fff!important'}}>
              {communityImageUrl?.title}
            </Typography>
            <Button className={'btn-glass'} variant={'contained'} 
              sx={{height: 50, width: 250, borderRadius: 10}} 
              onClick={() => {
                navigate(communityImageUrl?.btn_link)
              }}>{communityImageUrl?.btn_text}
            </Button>
          </Grid2> 
        
        </MobileView>

      </Box>

        
        <Box height={16} />

    </Grid2>

  );
}
