import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, Button, Autocomplete, TextField } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';

import './AddEventTicket.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { MOCK_EVENT, MOCK_EVENT_DATA } from '../../data/local/mock';

import { useHistory ,useLocation } from 'react-router-dom';
import TicketListingForm from './components/TicketListingForm';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClockIcon from '@mui/icons-material/ScheduleOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';

export default function AddEventTicket() {

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const _event = MOCK_EVENT;

  const params = useParams();

  const [eventId, setEventId] = useState();
  const [event, setEvent] = useState();

  useEffect(() => {
    setEventId(params.event_id);
  },[])

  useEffect(() => {
    // fetch event details
    setEvent(_event);
  },[eventId]);

  return (
    <Page title="Add Event Tickets" sx={{backgroundColor:'#f9f9f9'}}>
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'header header-transparent'} container style={{ width:'100%'}}>
                <Header />
            </Grid2>
     
            <Grid2 container direction={'column'} alignItems={'center'} justifyContent={'flex-start'} sx={{width:'100%'}}>

                <Grid2 container direction={'row'} sx={{width:'100%'}}>

                  <Grid2 container size={{xl:8, lg: 8, md: 8, sm:12, xs:12}} justifyContent={'center'} alignItems={'center'} order={{xl: 1, lg: 1, md: 1, sm: 2, xs: 2}} sx={{display:'inline-block',minHeight:'60vh'}}>
                    <TicketListingForm event={event} />
                  </Grid2>

                  <Grid2 container padding={4} direction={'column'} size={{xl:4, lg: 4, md: 4, sm:12, xs:12}} justifyContent={'flex-start'} alignItems={'center'} order={{xs: 1, sm: 1, md: 2, lg:2, xl:2}} sx={{display:'inline-block'}}>

                    {
                      event &&
                      <Card sx={{width:'100%'}}>
                        <Grid2 container direction={'column'} sx={{padding:1, width:'100%'}}>
                          <Typography variant="h5">{event.title}</Typography>
                          <hr />
                          <Grid2 container direction={'row'} sx={{width:'100%'}} columnSpacing={1}>
                            <CalendarIcon />
                            <Typography variant="body1">{event.date}</Typography>
                          </Grid2>
                          <Grid2 container direction={'row'} sx={{width:'100%'}} columnSpacing={1}>
                            <ClockIcon />
                            <Typography variant="body1">{event.start_time} onwards</Typography>
                          </Grid2>
                          <Grid2 container direction={'row'} sx={{width:'100%'}} columnSpacing={1}>
                            <LocationIcon />
                            <Typography variant="body1">{event.venue.name}, {event.venue.city}, {event.venue.country}</Typography>
                          </Grid2>
                          <Box height={16} />

                        </Grid2>
                      </Card>
                    }

                  </Grid2>

                </Grid2>

                <Box height={32} />

            </Grid2>

            <Footer />
        </Grid2>
    </Page>
  );
}
