import { createSlice } from '@reduxjs/toolkit';
import {SNACKBAR_SEVERITY } from '../../constants/app_constants';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    loadingStatus: false,
    loadingKey: 'loader-root',
    snackbarVisibilityStatus: false,
    snackbarMessage: null,
    snackbarSeverity: SNACKBAR_SEVERITY.INFO, 
    loginModalVisibilityStatus: false,
    darkMode: false,
  },
  reducers: {
    setLoadingStatus(state, action) {
        state.loadingStatus = action.payload.status;
        if (action.payload.status === false){
            state.loadingKey = 'loader-root'
        }else {
            if (action.payload.key) {
                state.loadingKey = action.payload.key;
            }
        }
    },
    setLoadingKey(state, action) {
        state.loadingKey = action.payload.key;
    },
    setSnackbarVisibilityStatus(state, action) {
      state.snackbarVisibilityStatus = action.payload.status;
    },
    setSnackbarMessage(state, action) {
        state.snackbarMessage = action.payload.message;
        state.snackbarSeverity = action.payload.severity;
    }, 
    setLoginModalVisibilityStatus(state, action) {
        state.loginModalVisibilityStatus = action.payload.status;
    },
    setDarkModeStatus(state, action) {
        state.darkMode = action.payload.status;
    },
    resetlayoutState(state, action){
      state.loadingStatus = false;
      state.loadingKey = 'loader-root';
      state.snackbarVisibilityStatus = false;
      state.snackbarMessage =  null;
      state.snackbarSeverity = SNACKBAR_SEVERITY.INFO;
      state.loginModalVisibilityStatus = false;
      state.darkMode = false;
    }, 
  }
})

export const getDarkModeStatus = (state) => {

    try{
        return state.layout.darkMode;
    }catch(e){
        return false;
    } 
}

export const getLoadingStatus = (state) => {

    try{
        return state.layout.loadingStatus;
    }catch(e){
        return false;
    } 
}

export const getLoadingKey = (state) => {

    try{
        return state.layout.loadingKey;
    }catch(e){
        return false;
    } 
}


export const getSnackbarVisibilityStatus = (state) => {

    try{
        return state.layout.snackbarVisibilityStatus;
    }catch(e){
        return false;
    } 
}


export const getSnackbarMessage = (state) => {

    try{
        return state.layout.snackbarMessage;
    }catch(e){
        return false;
    } 
}


export const getSnackbarSeverity = (state) => {

    try{
        return state.layout.snackbarSeverity;
    }catch(e){
        return false;
    } 
} 

export const getLoginModalVisibilityStatus = (state) => {

    try{
        return state.layout.loginModalVisibilityStatus;
    }catch(e){
        return false;
    }
}

 
export const { setLoadingStatus, setSnackbarVisibilityStatus, setSnackbarMessage,
    setSnackbarSeverity, setLoginModalVisibilityStatus, setLoadingKey, setDarkModeStatus
} = layoutSlice.actions

export default layoutSlice.reducer