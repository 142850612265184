import {Snackbar, Alert, Modal, Typography, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery, Box, Grid2 } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

import logo from './logo.svg';
import './App.css';

import { getSnackbarVisibilityStatus, getSnackbarMessage,setLoadingStatus,
  getSnackbarSeverity, setSnackbarVisibilityStatus, getLoadingStatus,
  getLoginModalVisibilityStatus,
  setLoginModalVisibilityStatus
} from './store/reducers/layout_reducer';
// import ScrollToTop from './components/ScrollToTop';
// import LoginForm from './pages/Login/components/LoginForm';

// import { BottomSheet } from 'react-spring-bottom-sheet'
// import 'react-spring-bottom-sheet/dist/style.css'
// import {Sheet} from 'react-modal-sheet';

// import {isMobile} from 'react-device-detect';
import LoginModal from './components/Modal/LoginModal/LoginModal';
import { GA_TRACKING_ID, GTM_ID } from './constants/app_constants';
import ReactGA from "react-ga4";

// import { ThemeProvider } from "styled-components";
// import GlobalStyle from "./theme/GlobalStyle";
// import { lightTheme, darkTheme } from "./theme/theme";

function App() {

  const dispatch = useDispatch();
  const isLoading = useSelector(state => getLoadingStatus(state));

  const showSnackbar = useSelector(state => getSnackbarVisibilityStatus(state));
  const snackbarMessage = useSelector(state => getSnackbarMessage(state));
  const snackbarSeverity = useSelector(state => getSnackbarSeverity(state));

  // useEffect(() => {
  //   const tagManagerArgs = {
  //       gtmId: GTM_ID
  //   };
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  ReactGA.initialize(GA_TRACKING_ID);

  return (
    <>
    {/* <LoadingOverlay
       active={isLoading}
       spinner
       text='Loading...'>*/}
      {/* <Themepe */}
        <ThemeProvider>
          {/* <ScrollToTop /> */}
          <Router />
        </ThemeProvider>
        
        
        <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={(e) => {
            dispatch(setSnackbarVisibilityStatus({'status' : false}));
          }}>
          <Alert onClose={( ) => { }} 
            severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>


        <LoginModal />

    {/* </LoadingOverlay> */}
    </>
  );
}

export default App;
