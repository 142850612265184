import { Sheet } from 'react-modal-sheet';
import { styled } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid2, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import TicketListingForm from '../../../pages/AddEventTicket/components/TicketListingForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    backdropFilter: 'blur(4px)',
    background: 'rgba(0,0,0,0.4) !important',
  }
  .react-modal-sheet-container {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    background: 'white !important',
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    /* custom styles */
    px: 4,
    pb: 12,
  }
`;

export default function AddTicketModal({event, open, onClose}) {

  const ref = useRef();
  const dispatch = useDispatch();

  const springConfig = {stiffness: 150, damping: 20, mass: 0.7 };

  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const toggleFormSuccess = () => {
    setIsFormSuccess(!isFormSuccess);
  }

  const handleFormAck = (success) => {
    console.log(success);
    if (success) {
        setIsFormSuccess(true);
    }else{
        setIsFormSuccess(false);
    }
  }

  useEffect(() => {

  },[]);

  return (
    <>
      <CustomSheet isOpen={open} 
        snapPoints={[700]} 
        springConfig={springConfig}
        ref={ref}
        onClose={onClose}
        style={{zIndex: 10}}
      >
        <Sheet.Container style={{padding:10}}>
            <Sheet.Header style={{borderBottom: '1px solid gray'}}>
                <Grid2 container padding={2} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h4">Add Ticket</Typography>
                <Box sx={{borderRadius:10, background:'#e0e0e0', padding:1}}>
                    <CloseIcon onClick={onClose} />
                </Box>
                </Grid2>
            </Sheet.Header>
            <Sheet.Content disableDrag style={{ paddingBottom: ref.current?.y }}>
                {
                    !isFormSuccess && <Sheet.Scroller>
                        <TicketListingForm event={event} next={handleFormAck} />
                    </Sheet.Scroller>
                }
                {
                    isFormSuccess && <Sheet.Scroller>
                        <Grid2 container justifyContent={'center'} rowSpacing={4} flexDirection={'column'} alignItems={'center'} p={2} sx={{width:'100%'}}>
                            
                            <CheckCircleIcon sx={{fontSize:'64px', color:'green'}} />
                            
                            <Typography variant="h4">Congratulations</Typography>

                            <Typography variant="body1">
                                Your listing has been sent for approval. We will reach out to you soon. 
                            </Typography>

                            <Button className={'btn btn-primary'} onClick={onClose}>
                                Close
                            </Button>

                        </Grid2>
                    </Sheet.Scroller>
                }
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} style={{backdropFilter: 'blur(10px)'}}/>
      </CustomSheet>
    </>
  );
}