import { createSlice } from '@reduxjs/toolkit'
// import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const eventSlice = createSlice({
  name: 'event',
  initialState: {
    featuredEventData: null,
    eventData: null,
    eventDetail: null,
  },
  reducers: {
    setFeaturedEventData(state,action) {
      state.featuredEventData = {
        'data': action.payload.data,
        'pagination': action.payload.pagination 
      }
    },
    setEventData(state,action) {
        let _data = [];
        if (action.payload.pagination?.page > 1 ){
            _data = [
                ...state.eventData.data,
                ...action.payload.data
            ];
        }else{
            _data = action.payload.data;
        }
        state.eventData = {
            'data': _data,
            'pagination': action.payload.pagination 
        }
    },
    setEventDetail(state,action) {
      state.eventDetail = action.payload.data; 
    },
    updateEventFollowStatus(state, action) {
        try{
            const _featuredEventData = state.featuredEventData.data;
            _featuredEventData.forEach(element => {
                if (element.slug == action.payload.event_id){
                    element.is_following = action.payload.status
                }
            });
            state.featuredEventData.data = _featuredEventData;
        }catch(e){
            console.log(e);
        }
    },
    resetEventState(state, action){
      state.featuredEventData = null;
      state.eventData = null;
      state.eventDetail = null;
    }
  }
})

export const getFeaturedEventData = (state) => {
    try{
        return state.event.featuredEventData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getFeaturedEventList = (state) => {
    try{
        return state.event.featuredEventData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getFeaturedEventPagination = (state) => {
    try{
        return state.event.featuredEventData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}


export const getEventData = (state) => {
    try{
        return state.event.eventData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getEventList = (state) => {
    try{
        return state.event.eventData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getEventPagination = (state) => {
    try{
        return state.event.eventData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getEventDetail = (state) => {
    try{
        return state.event.eventDetail;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const { setFeaturedEventData, setEventData, setEventDetail, resetEventState, updateEventFollowStatus
} = eventSlice.actions
export default eventSlice.reducer