import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui

// react redux
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Button, Card, Grid2, Typography } from '@mui/material';
import Page from '../../components/Page';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import './Events.css';

export default function SuccessPage() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

//   const events = useSelector(state => getFeaturedEventList(state)); 
  // const events = MOCK_EVENT_DATA;
 
  const handleViewAllEventClick = (event) => {
    navigate('/explore');
  }

  useEffect(() => {
    // fetchFeaturedEventList(dispatch, cookies);
  },[]);

  return (
    <Page title="Success">

            <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>

                <Grid2 className={'header header-transparent'} container style={{minHeight:'40px', width:'100%'}}>
                    <Header />
                </Grid2>

                <Grid2 container justifyContent={'center'} rowSpacing={4} flexDirection={'column'} alignItems={'center'} p={2} sx={{width:'100%'}}>
                
                            
                    <Card>
                        <Grid2 container justifyContent={'center'} rowSpacing={4} flexDirection={'column'} alignItems={'center'} p={2} sx={{width:'100%'}}>
                            
                            <CheckCircleIcon sx={{fontSize:'64px', color:'green'}} />
                            
                            <Typography variant="h4">Congratulations</Typography>

                            <Typography variant="body1">
                                Your listing has been sent for approval. We will reach out to you soon. 
                            </Typography>

                            <Button className={'btn btn-primary'} onClick={() => navigate('/')}>
                                Go to Home
                            </Button>

                        </Grid2>
                    </Card>
                </Grid2>
            </Grid2>
        <Footer />
    </Page>

  );
}
