import React, { useEffect, useState } from 'react';
import Iconify from '../../Iconify';
import { Box, Card, CardActions, CardContent, CardMedia, Grid2, IconButton, Stack, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import './CategoryCard.css';
import { useNavigate } from 'react-router-dom';
import { followCategory } from '../../../data/network/category';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getLoginStatus } from '../../../store/reducers/user_reducer';
import { setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { CategoryFallbackImage } from '../../../constants/file_constants';
import { isMobile } from 'react-device-detect';
import { getSingleS3Url } from '../../../utils/aws';

export default function CategoryCard({key, category}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const [isFeaturedImageLoading, setIsFeaturedImageLoading] = useState(false);
    const [featuredImageUrl, setFeaturedImageUrl] = useState(null);
    
    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleFollowCategory = (e, follow) => {
        e.stopPropagation();

        if (isLoggedIn){
            followCategory(dispatch, cookies, {'category_id': category?.slug, 'follow': follow});
        }else{
            // show login modal
            dispatch(setLoginModalVisibilityStatus({'status' : true}));
        }
    }

    const fetchImagesUrl = async () => {
        try{
          setIsFeaturedImageLoading(true);
          const _data = {
            'file_path' : isMobile ? category?.featured_image?.file_path_mobile : category?.featured_image?.file_path_mobile,
            'file_type' : isMobile ? category?.featured_image?.file_type_mobile : category?.featured_image?.file_type_mobile,
          } 
          
          const _featuredImageUrl = await getSingleS3Url(_data);
          setFeaturedImageUrl(_featuredImageUrl);
        }catch(e) {
          console.log(e);
        }
        setIsFeaturedImageLoading(false);
    }
  
    useEffect(() => {
        fetchImagesUrl();
    },[]);

    return (
        <Stack key={key} container flexDirection={'column'} className={'event-card'}
            sx={{ background:'transparent!important', width:'100%' }} 
            onClick={() => {
                // navigate(`${category.link}`)
            }
        }>
            { 
                (!isFeaturedImageLoading && featuredImageUrl) ? <img className={'category-card-img'} src={category?.image_url} alt={category?.title} height={'150'} width={'100%'} 
                    onError={(e) => {
                        e.currentTarget.src = CategoryFallbackImage;
                        e.currentTarget.style = 'opacity: 0.5';
                    }} 
                /> : <img className={'category-card-img'} src={CategoryFallbackImage} alt={category?.title} height={'150'} width={'100%'} 
                        style={{opacity: 0.5}}
                    />
            }
            

            <Grid2>
            <Typography variant="body1" py={2} fontWeight={500}>
                {category.display_name}
                </Typography>
            </Grid2>

            <Box className={'category-card-follow-icon'} onClick={(e) => handleFollowCategory(e, !category?.is_following)}>
                <IconButton aria-label="add to favorites">
                    {category?.is_following ? <FavoriteIcon color={'error'} /> : <FavoriteIcon />}
                </IconButton>
            </Box>

            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
            </CardActions> */}
            
        </Stack>
    );
}