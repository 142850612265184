import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/reducers/layout_reducer';
import { setUser } from '../../store/reducers/user_reducer';
import axios from '../../utils/axios-configure';
import {  API_GET_USER_DETAILS, API_UPDATE_USER_DETAILS } from "../endpoints";
import {getAccessTokenFromCookie} from './session'
import { SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { PROFILE_UPDATE } from '../../constants/message_constants';

export const fetchUserDetails = (dispatch, cookies) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_USER_DETAILS}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setUser({data: response.data.data}));
            }else{
              dispatch(setUser({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setUser({data: {}}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
      dispatch(setUser({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const updateUserDetails = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_UPDATE_USER_DETAILS}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            dispatch(setUser({data: response.data.data}));
            dispatch(setSnackbarMessage({message: PROFILE_UPDATE.SUCCESS ,severity:SNACKBAR_SEVERITY.SUCCESS}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          }else{
            dispatch(setUser({data: {}}));
            dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setUser({data: {}}));
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
          dispatch(setSnackbarVisibilityStatus({status: true}));
        // console.log(response);
        });
  }else{
    dispatch(setUser({data: {}}));
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
    dispatch(setSnackbarVisibilityStatus({status: true}));
}
}
