import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Grid2 } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../../../../components/Page';
import Carousel from '../../../../components/Carousel';
import { getBannerImages } from '../../../../store/reducers/config_reducer';

import './LandingPage.css';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import Events from './components/Events';
import FAQs from './components/FAQs';
import Contact from './components/Contact';
import Community from './components/Community';
import { APP_NAME } from '../../../../constants/app_constants';
import HeaderDark from '../../../../components/Header/HeaderDark';
import { BANNER_IMAGE_CONSTANTS } from '../../../../constants/file_constants';
import PopularCategory from './components/PopularCategory';
import PopularArtist from './components/PopularArtist';
import { useEffect, useState } from 'react';
import { getS3Url } from '../../../../utils/aws';
import Loading1 from '../../../../components/Loader/loader_1/Loading1';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import ReactGA from 'react-ga4';

export default function LandingPage() { 

  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [isBannerImageLoading, setIsBannerImageLoading] = useState(true);
  const [bannerImageUrl, setBannerImageUrl] = useState([]);
  const bannerImages = useSelector(state => getBannerImages(state)); 
  // const bannerImages = BANNER_IMAGE_CONSTANTS;

  const fetchBannerImagesUrl = async (images) => {
    try{
      const bannerImagesWithUrl = await getS3Url(images);
      setBannerImageUrl(bannerImagesWithUrl);
      setIsBannerImageLoading(false);
    }catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setIsBannerImageLoading(true);
    console.log(bannerImages);

    // const _images = [];
    // for (const i in bannerImages){
    //   _images.push(bannerImages[i].image);
    // }
    fetchBannerImagesUrl(bannerImages);
  },[bannerImages]);



  return (
    <Page title={APP_NAME}>
      <Box sx={{position:'absolute', top:0, left:0, right:0, zIndex: 10}}> 
        <HeaderDark />
      </Box>
      <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>

        <Grid2 className={'landing-header'} container style={{minHeight:'100vh', width:'100%'}}>
            {/* <HeaderDark /> */}
            <Grid2 container style={{minHeight:'100px', width:'100%'}}>
              {
                (!isBannerImageLoading && bannerImageUrl?.length>0) 
                ? <Carousel dataList={bannerImageUrl} navigate={navigate} />
                : <Carousel dataList={BANNER_IMAGE_CONSTANTS} navigate={navigate} />
              }
            </Grid2>
        </Grid2>
        <Events />
        <PopularCategory />
        <PopularArtist />
        <Community />
        {/* <FAQs /> */}
        <Contact />
        <Footer />
      </Grid2>
    </Page>
      
  );
}
