
export const BANNER_IMAGE_CONSTANTS = [
    {
        'title': '',
        'subtitle': '',
        'image' : {
            'file_path_web': 'web/banner/banner_1.jpg',
            'file_type_web': 'image/jpeg',
            'file_path_mobile': 'web/banner/banner_1.jpg',
            'file_type_mobile': 'image/jpeg',
            'file_url_web': '../../static/banner-web-1.png',
            'file_url_mobile': '../../static/banner-mobile-1.png',
        },
        'btn_link': '/explore',
        'btn_text': 'Explore'
    },
    // {
    //     'title': 'Dil-Luminati Tour',
    //     'subtitle': '',
    //     'image' : {
    //         'file_path_web': 'web/banner/banner_2.jpg',
    //         'file_type_web': 'image/jpeg',
    //         'file_path_mobile': 'web/banner/banner_2.jpg',
    //         'file_type_mobile': 'image/jpeg',
    //     },
    //     'btn_link': '/event/dil-luminati-tour-new-delhi-2024',
    //     'btn_text': 'See event'
    // },
    // {
    //     'file_path': 'banner/banner_3.jpg',
    //     'file_type': 'image/jpeg',
    // },
]

export const COMMUNITY_SECTION_BANNER = {
    'title': 'Enhance your experience',
    'subtitle': '',
    'image' : {
        'file_path_web': 'web/community/community.png',
        'file_type_web': 'image/png',
        'file_path_mobile': 'web/community/community.png',
        'file_type_mobile': 'image/png',
    },
    'btn_link': '/explore',
    'btn_text': 'Join us'
};


export const BannerFallbackImage = "../../static/banner-fallback.png";
export const EventFallbackImage = "../../static/event-fallback.png";
export const ArtistFallbackImage = "../../static/artist-fallback.png";
export const CategoryFallbackImage = "../../static/category-fallback.png";


