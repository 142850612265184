import { Sheet } from 'react-modal-sheet';
import { styled } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid2, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoginForm from '../../../pages/Login/components/LoginForm';
import { getLoginModalVisibilityStatus, setLoginModalVisibilityStatus } from '../../../store/reducers/layout_reducer';

import './LoginModal.css';

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    backdropFilter: 'blur(4px)',
    background: 'rgba(0,0,0,0.4) !important',
  }
  .react-modal-sheet-container {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    background: 'white !important',
    zIndex: '99!important',
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    /* custom styles */
    px: 4,
    pb: 12,
  }
`;

export default function LoginModal({event, open, onClose}) {

    const ref = useRef();
  const dispatch = useDispatch();

  const showLoginModal = useSelector(state => getLoginModalVisibilityStatus(state));
  const springConfig = {stiffness: 150, damping: 20, mass: 0.7 };

  const handleLoginModalClose = () => {
    dispatch(setLoginModalVisibilityStatus({status: false}));
  }

  return (
    <>
      <CustomSheet isOpen={showLoginModal} 
      className='login-modal-container'
        springConfig={springConfig}
        // snapPoints={[600]}
        ref={ref}
        onClose={handleLoginModalClose}
        detent="content-height"
        // initialSnap={[100]}
      >
        <Sheet.Container style={{padding:10}}>
          <Sheet.Header  style={{borderBottom: '1px solid gray'}}>
            <Grid2 container padding={2} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h4">Login</Typography>
              <CloseIcon onClick={handleLoginModalClose}  />
            </Grid2>
           
          </Sheet.Header>
          <Sheet.Content disableDrag style={{ paddingBottom: ref.current?.y }}>
            <Box my={2} />
            <LoginForm />
            <Box my={1} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop  onTap={handleLoginModalClose} style={{backdropFilter: 'blur(10px)'}}/>
      </CustomSheet>
    </>
  );
}