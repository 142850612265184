import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box, Button } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// import './Events.css';
import { MOCK_EVENT_DATA, MOCK_TICKET_LIST_DATA } from '../../../data/local/mock';
import EventCard from '../../../components/Cards/EventCard/EventCard';
import TicketListCard from './TicketListCard';
import { fetchListingForEvent } from '../../../data/network/listing';
import { getListingList } from '../../../store/reducers/listing_reducer';
import Loader2 from '../../../components/Loader/loader_2/Loader2';

export default function TicketList({event}) { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [isLoading, setIsLoading] = useState(false);

//   const events = useSelector(state => getEvents(state)); 
//   const ticketList = MOCK_TICKET_LIST_DATA;
  const ticketList = useSelector(state => getListingList(state));

  const loadEventListing = () => {
    setIsLoading(true);
    fetchListingForEvent(dispatch, cookies, event?.slug);
  }
  
  useEffect(() => {
    setIsLoading(false);


  },[ticketList]);

  useEffect(() => {
    loadEventListing();
  },[]);

  return (
    <Grid2 item container borderRadius={1} sx={{margin:'0!important',width:'100%!important'}}>
        <Grid2 container justifyContent={'center'} alignItems={'center'} sx={{width:'100%'}}>
            <Grid2 container direction={'row'} justifyContent={'space-between'} padding={1} alignItems={'center'} sx={{width:'100%', backgroundColor: '#fff'}}>
            
                <Typography variant={'body1'}>
                    {`${ticketList.length} listings`}
                </Typography>

                {/* <Typography variant={'body1'}>
                    Sort by price
                </Typography> */}

            </Grid2>

            <hr />

            {/* <Box sx={{border: 1, borderColor: 'gray', width:'100%'}} /> */}
            
            {
                isLoading && <Grid2 container margin={1} rowSpacing={1} justifyContent={'center'} alignItems={'flex-start'}>
                    <Loader2 />
                </Grid2>
            }

            {
                !isLoading && <Grid2 container margin={1} rowSpacing={1} sx={{width:'100%'}}>
                    {
                        ticketList && ticketList.map((ticket) => {
                            return (
                                <TicketListCard ticket={ticket} />
                            );
                        })
                    }

                    {
                        ticketList?.length === 0 &&
                        <Grid2 container margin={1} rowSpacing={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{width:'100%'}}>
                            <Typography variant='h6' sx={{wordWrap:'wrap'}}>No tickets available. </Typography>
                            <Typography variant='subtitle1' sx={{wordWrap:'wrap'}}>We are constantly working on bringing more tickets to you. </Typography>
                            <Button variant={'outlined'} className={'btn btn-transparent'} onClick={loadEventListing}>Retry</Button>
                        </Grid2>
                    }
                </Grid2>
            }

        </Grid2>
    </Grid2>

  );
}
