import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Typography, Grid2, Box } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import CategoryCard from '../../../../../components/Cards/CategoryCard/CategoryCard';
import { getFeaturedCategoryList } from '../../../../../store/reducers/category_reducer';
import { fetchFeaturedCategoryList } from '../../../../../data/network/category';
import Loader2 from '../../../../../components/Loader/loader_2/Loader2';
import { getLoadingStatus } from '../../../../../store/reducers/layout_reducer';

export default function PopularCategory() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [loadingCategories, setLoadingCategories] = useState(false);

  const isLoading = useSelector(state => getLoadingStatus(state));
  const categories = useSelector(state => getFeaturedCategoryList(state)); 
  // const categories = MOCK_POPULAR_CATEGORY_LIST;
 
  useState(() => {
    // setLoadingCategories(false);
  },[isLoading]);

  useEffect(() => {
    // setLoadingCategories(true);
    fetchFeaturedCategoryList(dispatch, cookies);
  },[]);


  return (
    
      !isLoading && categories?.length >0 && <Grid2 className={'section section-category'} container direction={'column'} px={4} py={2} style={{width:'100%'}}> 
          <Typography variant="h5" className={'section-title'}>Popular Category</Typography>
          <Box height={16} />

          <Grid2 container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} rowSpacing={4} columnSpacing={4}>
          {
              categories?.map((category, index) => {
                  return <Grid2 container size={{xl:3, lg:3, md: 4, sm:6, xs:6}}>
                    <CategoryCard
                        key={index}
                        category={category}
                    />
                  </Grid2>
              })
          }
          </Grid2>

      </Grid2>
    

  );
}
