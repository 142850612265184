import { createSlice } from '@reduxjs/toolkit'
// import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const artistSlice = createSlice({
  name: 'artist',
  initialState: {
    featuredArtistData: null,
    artistData: null,
    artistDetail: null,
  },
  reducers: {
    setFeaturedArtistData(state,action) {
      state.featuredArtistData = {
        'data': action.payload.data,
        'pagination': action.payload.pagination 
      }
    },
    setArtistData(state,action) {
      state.artistData = {
        'data': action.payload.data,
        'pagination': action.payload.pagination 
      }
    },
    setArtistDetail(state,action) {
      state.artistDetail = action.payload.data; 
    },
    updateArtistFollowStatus(state, action) {
        try{
            const _featuredArtistData = state.featuredArtistData.data;
            _featuredArtistData.forEach(element => {
                if (element.slug == action.payload.artist_id){
                    element.is_following = action.payload.status
                }
            });
            state.featuredArtistData.data = _featuredArtistData;
        }catch(e){
            console.log(e);
        }
    },   
    resetArtistState(state, action){
      state.featuredArtistData = null;
      state.artistData = null;
      state.artistDetail = null;
    }
  }
})

export const getFeaturedArtistData = (state) => {
    try{
        return state.artist.featuredArtistData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getFeaturedArtistList = (state) => {
    try{
        return state.artist.featuredArtistData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getFeaturedArtistPagination = (state) => {
    try{
        return state.artist.featuredArtistData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}


export const getArtistData = (state) => {
    try{
        return state.artist.artistData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getArtistList = (state) => {
    try{
        return state.artist.artistData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getArtistPagination = (state) => {
    try{
        return state.artist.artistData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getArtistDetail = (state) => {
    try{
        return state.artist.artistDetail;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const { setArtistData, setFeaturedArtistData, setArtistDetail, resetArtistState, updateArtistFollowStatus
} = artistSlice.actions
export default artistSlice.reducer