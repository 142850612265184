import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox,Grid, TextField, IconButton, Box, InputAdornment, FormControlLabel, Container, Card, Grid2 } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDispatch, useSelector } from "react-redux";

import { getOTPSentStatus, getOTPVerificationStatus, 
   getClientId, getClientSecret, 
   clearOTPState, getLoggedinUserId } from '../../../store/reducers/auth_reducer'; 
import { sendOTP,  verifyOTP,  } from '../../../data/network/otp';
import { generateAccessToken, getSession } from '../../../data/network/session';
import { getLoginStatus, getUser, getUserId } from '../../../store/reducers/user_reducer';

// component
import { fetchUserDetails } from '../../../data/network/user';
import { OTP_ERROR } from '../../../constants/message_constants';
import { setLoginModalVisibilityStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/reducers/layout_reducer';
import { logEvent } from '../../../utils/ga';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showOTPContainer, setShowOTPContainer] = useState(false);
  const [otpValue, setOTPValue] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required'),
  // });

  const phoneRegExp = /^((\\+[5-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long"),
    // email('Please enter valid mobile number').required('Mobile number is required'),
    otp: Yup.string() 
    .matches(/^[0-9]{4}$/gi, "Enter valid OTP")
    .length(4, 'Enter valid OTP')
    .required('OTP is required')
    // .min(4, "OTP is too short")
    // .max(4, "OTP is too long"), 
  });

  const formik = useFormik({
    initialValues: {
      mobile:  cookies.mobile ,
      otp: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

      // call verify OTP Api here

      // navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const otpSent = useSelector((state) => getOTPSentStatus(state));
  const otpVerified = useSelector((state) => getOTPVerificationStatus(state));
  const userId = useSelector((state) => getLoggedinUserId(state));

  const clientId = useSelector((state) => getClientId(state));
  const clientSecret = useSelector((state) => getClientSecret(state));
  const isLoggedIn = useSelector((state) => getLoginStatus(state));
  const userDetails = useSelector((state) => getUser(state));

  useEffect(() => {
    dispatch(clearOTPState());
    getSession(dispatch, cookies, removeCookie);
  },[]);

  useEffect(() => {
    if(otpSent){
      setShowOTPContainer(true);
    }else{
      setShowOTPContainer(false);
    }
  },[otpSent]);

  useEffect(() => {
    if(otpVerified){
      generateAccessToken(dispatch, cookies, setCookie, userId, clientId, clientSecret);
    }
  },[otpVerified]);

  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
      dispatch(setLoginModalVisibilityStatus({'status': false }));
    }
  },[isLoggedIn]);

  const handleSendOTPClick = () => {  
    logEvent({'category':'otp', 'action': 'send_otp', 'label': cookies.mobile});

    if( errors.mobile || cookies.mobile === undefined){
      // show snackbar with error message
      displayMessage("error", OTP_ERROR.INVALID_MOBILE)  
    }else{
      // call send OTP Api here
     console.log(cookies.mobile);
      sendOTP(dispatch, cookies, {
        mobile: cookies.mobile,
        // user_type: "CORPORATE",
        // corporate_client_id: selectedCorporate.id
      }); 
    }
  }

  const handleVerifyOTPClick = () => {
    // call verify OTP Api here 
    logEvent({'category':'otp', 'action': 'verify_otp', 'label': cookies.mobile});

    if( errors.otp){
       // show snackbar with error message
      displayMessage("error", OTP_ERROR.INVALID_OTP)  
    }else{ 
      // call send OTP Api here
      verifyOTP(dispatch, cookies, {
        'mobile': cookies.mobile,
        'otp': otpValue
      });
    }
  }

  const displayMessage = (type, message ) => {
    // show Snackbar
    dispatch(setSnackbarMessage({'message': message, 'severity': type}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="mobile_number"
            type="number"
            label="Mobile Number"
            onKeyUp={e => {
              console.log(e.target.value);
              setCookie("mobile", e.target.value, { path: '/' })
            }}
            value={cookies.mobile}
            {...getFieldProps('mobile')}
            error={Boolean(touched.mobile && errors.mobile)}
            helperText={touched.mobile && errors.mobile}
          />
        { 
          showOTPContainer  
          ? <TextField
            fullWidth
            autoComplete="current-otp"
            // type="number"
            // type={showPassword ? 'number' : 'password'}
            label="OTP"
            onKeyUp={e => {
              setOTPValue(e.target.value);
            }}
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          : <Container />  }
        </Stack>

        { showOTPContainer  ? 
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => handleSendOTPClick()}>
            Resend OTP?
          </Link>
        </Stack>
        : <Container />  }

        <Grid2 container justifyContent={'center'} sx={{width:'100%'}}>

          { !showOTPContainer  && errors.mobile  ? 
            <LoadingButton className={'btn btn-primary'} fullWidth size="large" onClick={() => displayMessage("error", OTP_ERROR.INVALID_MOBILE)} variant="contained" loading={isSubmitting}>
              Send OTP
            </LoadingButton>
            : null  
          }

          { !showOTPContainer  && !errors.mobile  ? 
            <LoadingButton  className={'btn btn-primary'} fullWidth size="large" onClick={() => handleSendOTPClick()} variant="contained" loading={isSubmitting}>
              Send OTP
            </LoadingButton>
            : null  
          }

          { showOTPContainer && errors.otp  ? 
          <LoadingButton  className={'btn btn-primary'} fullWidth size="large" onClick={() => displayMessage("error", OTP_ERROR.INVALID_OTP)} variant="contained" loading={isSubmitting}>
            Verify OTP
          </LoadingButton> 
            : <Container />  }   

          { showOTPContainer && !errors.otp ? 
          <LoadingButton  className={'btn btn-primary'} fullWidth size="large" onClick={() => handleVerifyOTPClick()} variant="contained" loading={isSubmitting}>
            Verify OTP
          </LoadingButton> 
            : <Container />  }  

        </Grid2> 

      </Form>
    </FormikProvider>

  );
}
