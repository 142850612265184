import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Button, Grid2, Typography } from "@mui/material";
import { current } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Carousel = ({ dataList, navigate, showArrow=false }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const slideVariants = {
    hiddenRight: {
      x: "100%",
      opacity: 0,
    },
    hiddenLeft: {
      x: "-100%",
      opacity: 0,
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.1,
      },
    },
  };
  const slidersVariants = {
    hover: {
      scale: 1.2,
      backgroundColor: "#ff00008e",
    },
  };
  const dotsVariants = {
    initial: {
      y: 0,
    },
    animate: {
      y: -10,
      scale: 1.2,
      transition: { type: "spring", stiffness: 1000, damping: "10" },
    },
    hover: {
      scale: 1.1,
      transition: { duration: 0.2 },
    },
  };

  const handleNext = () => {
    setDirection("right");
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === dataList.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setDirection("left");

    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? dataList.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? "right" : "left");
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    dataList && dataList.length > 0 &&
      <div className="carousel" style={{backgroundColor: '#233243'}}>
          <div className="carousel-images">
            <AnimatePresence>
              <motion.img
                key={currentIndex}
                src= {isMobile ? dataList[currentIndex].image?.file_url_mobile : dataList[currentIndex].image?.file_url_web}
                initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
                animate="visible"
                // exit="exit"
                variants={slideVariants}
              />
            </AnimatePresence>
            {
              showArrow && <div className="slide_direction">
                <motion.div
                  variants={slidersVariants}
                  whileHover="hover"
                  className="left"
                  onClick={handlePrevious}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 96 960 960"
                    width="20"
                  >
                    <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
                  </svg>
                </motion.div>
                <motion.div
                  variants={slidersVariants}
                  whileHover="hover"
                  className="right"
                  onClick={handleNext}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 96 960 960"
                    width="20"
                  >
                    <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
                  </svg>
                </motion.div>
              </div>
              }
                <BrowserView>
                  {
                    dataList[currentIndex].title &&
                      <motion.div style={{top:0, left:0, right:0, position:'absolute', height:'100%', width:'100%'}}>
                        <Grid2 container direction={'column'} rowSpacing={4} justifyContent={'center'} alignItems={'flex-start'} p={4} sx={{color:'white', height:'100%'}}>
                          <Typography variant="h1" zIndex={100}>
                            {dataList[currentIndex].title}
                          </Typography>

                          <Typography variant="h4" zIndex={100}>
                            {dataList[currentIndex].subtitle}
                          </Typography>
                          
                          <Button className={'btn-glass'} variant={'contained'} 
                            sx={{height: 50, width: 250, borderRadius: 10}} 
                            onClick={() => {navigate(dataList[currentIndex].btn_link)}}
                          >
                            {dataList[currentIndex].btn_text}
                          </Button>

                        </Grid2>
                      </motion.div>
                    }
                </BrowserView>

                <MobileView>
                {
                    dataList[currentIndex].title &&
                      <motion.div style={{top:0, left:0, right:0, position:'absolute', height:'100%', width:'100%'}}>
                        <Grid2 container direction={'column'} rowSpacing={4} pb={10} justifyContent={'flex-end'} alignItems={'center'} p={4} sx={{color:'white', height:'100%'}}>
                          <Typography variant="h3" zIndex={10}>
                            {dataList[currentIndex].title}
                          </Typography>

                          <Typography variant="h6" zIndex={10}>
                            {dataList[currentIndex].subtitle}
                          </Typography>
                          
                          <Button className={'btn-glass'} variant={'contained'} 
                            sx={{height: 60,   fontWeight: 500,
                              fontSize: 20,
                              letterSpacing: 0.5,
                              transition: 0.3, width: '100%', borderRadius: 4}} 
                            onClick={() => navigate(dataList[currentIndex].btn_link)}
                          >
                            {dataList[currentIndex].btn_text}
                          </Button>

                          <Box margin={4} />

                        </Grid2>
                      </motion.div>
                    }
                </MobileView>
          </div>
        {/* <div className="carousel-indicator">
          {dataList.map((_, index) => (
            <motion.div
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
              initial="initial"
              animate={currentIndex === index ? "animate" : ""}
              whileHover="hover"
              variants={dotsVariants}
            ></motion.div>
          ))}
        </div> */}
      </div>
  );
};
export default Carousel;