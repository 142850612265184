import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid2, Box } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// import './Events.css';
import { MOCK_EVENT_DATA } from '../../../../../data/local/mock';
import EventCard from '../../../../../components/Cards/EventCard/EventCard';
import { fetchFeaturedEventList } from '../../../../../data/network/event';
import { getFeaturedEventList } from '../../../../../store/reducers/event_reducer';
import RightChevronIcon from '@mui/icons-material/ChevronRight';
import Loader2 from '../../../../../components/Loader/loader_2/Loader2';

export default function Events() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const [loadingEvents, setLoadingEvents] = useState(false);

  const events = useSelector(state => getFeaturedEventList(state)); 
  // const events = MOCK_EVENT_DATA;
 
  const handleViewAllEventClick = (event) => {
    navigate('/explore');
  }

  useEffect(() => {
    setLoadingEvents(false);
  },[events]);

  useEffect(() => {
    setLoadingEvents(true);
    fetchFeaturedEventList(dispatch, cookies);
  },[]);

  return (
    <Grid2 className={'section section-event'} container direction={'column'}  px={4} py={2} style={{width:'100%'}}> 
      <Grid2 container direction={'row'} justifyContent={'space-between'} alignItems={'center'}  py={2} style={{width:'100%'}}> 
        <Typography variant="h5" className={'section-title'}>Explore Events</Typography>
        <Grid2 className={'section-view-all'} container flexDirection={'row'} justifyContent={'center'} 
          onClick={handleViewAllEventClick}>
          <Typography variant="body1" className={'section-view-all'}>View all</Typography>
          <RightChevronIcon />
        </Grid2>
      </Grid2>
      <Box height={16} />

        <Grid2 container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} rowSpacing={4} columnSpacing={4} sx={{width:'100%'}}>
        {
            !loadingEvents && events && events.map((event, index) => {
                return <Grid2 key={index} container size={{xl:3, lg:3, md: 4, sm:6, xs:6}}>
                  <EventCard
                      key={index}
                      event={event}
                  />
                </Grid2>
            })
        }

        {
          loadingEvents && <Loader2 />
        }
        </Grid2>
    </Grid2>
  );
}
