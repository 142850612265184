import { createSlice } from '@reduxjs/toolkit';
import {DEFAULT_SUPPORT_DETAILS, SNACKBAR_SEVERITY, SOCIAL_MEDIA_LINKS, SUPPORT_DEFAULT_CONTACT, SUPPORT_DEFAULT_EMAIL } from '../../constants/app_constants';
import { MOCK_BANNER_IMAGES } from '../../data/local/mock';

const configSlice = createSlice({
  name: 'config',
  initialState: {
    bannerImages: MOCK_BANNER_IMAGES,
    supportDetails : DEFAULT_SUPPORT_DETAILS,
    socialMediaLinks: SOCIAL_MEDIA_LINKS,
    footerLinks: [],
    // snackbarVisibilityStatus: false,
    // snackbarMessage: null,
    // snackbarSeverity: SNACKBAR_SEVERITY.INFO, 
  },
  reducers: {
    setBannerImages(state, action) {
        state.bannerImages = action.payload.data;
    },
    setSupportDetails(state, action) {
      state.supportDetails = action.payload.data;
    },
    setSocialMediaLinks(state, action) {
      state.socialMediaLinks = action.payload.data;
    },
    setFooterLinks(state, action) {
      state.footerLinks = action.payload.data;
    },
    setSiteConfigResponse(state, action) {
      state.bannerImages = action.payload.data.banner_images;
      state.socialMediaLinks = action.payload.data.social_media_links;
      state.supportDetails = action.payload.data.support_details;
      state.footerLinks = action.payload.data.footer_links;
    },
    resetlayoutState(state, action){
      state.bannerImages = MOCK_BANNER_IMAGES;
      state.supportDetails = DEFAULT_SUPPORT_DETAILS;
      state.socialMediaLinks = SOCIAL_MEDIA_LINKS;
      state.footerLinks = [];
    //   state.snackbarVisibilityStatus = false;
    //   state.snackbarMessage =  null;
    //   state.snackbarSeverity = SNACKBAR_SEVERITY.INFO;
    }, 
  }
})

export const getBannerImages = (state) => {

    try{
        return state.config.bannerImages;
    }catch(e){
        return [];
    } 
}

export const getSupportDetails = (state) => {

  try{
    return state.config.supportDetails;
  }catch(e){
    console.log(e);
      return DEFAULT_SUPPORT_DETAILS;
  } 
}


export const getSocialMediaLinks = (state) => {

  try{
      return state.config.socialMediaLinks;
  }catch(e){
      return SOCIAL_MEDIA_LINKS;
  } 
}

export const getFooterLinks = (state) => {

  try{
      return state.config.footerLinks;
  }catch(e){
      return [];
  } 
}

// export const getSnackbarVisibilityStatus = (state) => {

//     try{
//         return state.layout.snackbarVisibilityStatus;
//     }catch(e){
//         return false;
//     } 
// }


// export const getSnackbarMessage = (state) => {

//     try{
//         return state.layout.snackbarMessage;
//     }catch(e){
//         return false;
//     } 
// }


// export const getSnackbarSeverity = (state) => {

//     try{
//         return state.layout.snackbarSeverity;
//     }catch(e){
//         return false;
//     } 
// } 
 
export const { setBannerImages, setSocialMediaLinks, setSupportDetails, setSiteConfigResponse, setFooterLinks
} = configSlice.actions

export default configSlice.reducer