import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography, Card, Grid2, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ProfileForm from './components/ProfileForm'; 
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
// import Navbar from '../components/Navbar';
import LogoutIcon from '@mui/icons-material/Logout';

import './Profile.css';
import { getLoginStatus, logoutUser } from '../../store/reducers/user_reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isAllOf } from '@reduxjs/toolkit';
import { useCookies } from 'react-cookie';
// ----------------------------------------------------------------------

export default function Profile() {
    const theme = useTheme();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleLogout = () => {
        removeCookie('mobile', { path: '/' });
        removeCookie('session', { path: '/' });
        removeCookie('access_token', { path: '/' });
        sessionStorage.removeItem('mobile');
        sessionStorage.removeItem('session');
        sessionStorage.removeItem('access_token');
        dispatch(logoutUser());
    }

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        }
    },[isLoggedIn]);

    return (
        <Page title="My Profile">

            <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>

                <Grid2 className={'header header-transparent'} container style={{minHeight:'40px', width:'100%'}}>
                    <Header />
                </Grid2>

                <Grid2 container justifyContent={'center'} rowSpacing={4} flexDirection={'column'} alignItems={'center'} p={2} sx={{width:'100%'}}>
                
            
                    <Grid2 className={'profile-container'} container justifyContent={'center'} rowSpacing={4} flexDirection={'column'} alignItems={'center'}>
                        {/* <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{width:'100%'}}>
                            <Typography py={1} variant="h5" className={'section-title'}>
                                {"My Profile"}
                            </Typography>
                        </Grid2> */}
                        <Card size={{lg:4, xl: 4, md:4, sm:6, xs: 12}} sx={{width:'100%'}}>
                            <Grid2 container px={1} direction={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{width:'100%'}}>
                                <Typography pt={2} variant="h4" className={'section-title'}>
                                    {"My Profile"}
                                </Typography>
                            </Grid2>
                            <Grid2 item container lg={6} sm={12} md={9} xl={6} borderRadius={1} spacing={3} sx={{margin:'0!important',width:'100%!important'}}>
                                
                                <Grid2  
                                    sx={{
                                        py: 4, 
                                        width:'100%',
                                        // textAlign: 'center',
                                        // color: (theme) => theme.palette[color].darker,
                                        // bgcolor: (theme) => theme.palette[color].lighter,
                                        // ...sx,
                                    }}
                                    // {...other}
                                >
                                    <ProfileForm />
                                </Grid2>
                            </Grid2>
                        </Card>

                        <Card className={'logout-btn-container'} size={{lg:4, xl: 4, md:4, sm:6, xs: 12}} sx={{width:'100%', cursor:'pointer'}} mt={4} onClick={handleLogout}>
                            <Grid2 container px={1} py={2} direction={'row'} columnSpacing={1} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                                <Typography variant="h6" className={'section-title'}>
                                    {"Logout from this account"}
                                </Typography>
                                <LogoutIcon />

                            </Grid2>
                        </Card>

                        
                    </Grid2>
                </Grid2>

                <Box height={16} />

                <Footer />
            </Grid2>
        </Page>
    );
}
