import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

import userReducer from './reducers/user_reducer'
import authReducer from './reducers/auth_reducer'
import layoutReducer from './reducers/layout_reducer' 
import navigationReducer from './reducers/navigation_reducer' 
import configReducer from './reducers/config_reducer' 
import pageReducer from './reducers/page_reducer' 
import eventReducer from './reducers/event_reducer' 
import artistReducer from './reducers/artist_reducer' 
import categoryReducer from './reducers/category_reducer' 
import listingReducer from './reducers/listing_reducer' 

const reducers = combineReducers({
    user: userReducer,
    auth: authReducer,
    layout: layoutReducer,
    navigation: navigationReducer,
    config: configReducer,
    page: pageReducer,
    event: eventReducer,
    artist: artistReducer,
    category: categoryReducer,
    listing: listingReducer
});
   
const persistConfig = {
    key: 'tickit-web-root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
 
const store = configureStore({
    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }), 
})

export default store