import {Buffer} from 'buffer';

import { API_GET_SESSION,API_CREATE_SESSION, API_GENERATE_ACCESS_TOKEN } from "../endpoints";
import axios from '../../utils/axios-configure';

import {
    setAccessToken,
    setSessionDetails,
    setLoginStatus,
    setUserName,
    logoutUser
} from '../../store/reducers/user_reducer';

import {
  clearOTPState
} from '../../store/reducers/auth_reducer';
import { fetchUserDetails } from './user';
import { setLoadingStatus } from '../../store/reducers/layout_reducer';
import { getDeviceInfo } from '../../utils/device_helper';

export const getSession = async (dispatch, cookies, removeCookie) => {
    dispatch(setLoadingStatus({status: true}));   
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        // const deviceInfo = await getDeviceInfo();
        axios({
            method: "get",
            url: API_GET_SESSION,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}`, 
            // "X-Device-Info": `${deviceInfo}`
             },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setSessionDetails({data: response.data.data}));
                if(response.data.data != null){
                  dispatch(setLoginStatus({data: true}));
                  dispatch(setUserName({data: response.data.data.user_first_name}));
                }
            }else{
              console.log("111111");
              removeCookie('mobile', { path: '/' });
            removeCookie('session', { path: '/' });
            removeCookie('access_token', { path: '/' });
            dispatch(logoutUser({data: {}}));
              dispatch(setSessionDetails({data: {}}));
              dispatch(setLoginStatus({data: false}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            console.log(err);
            console.log("123333333");
            removeCookie('mobile', { path: '/' });
            removeCookie('session', { path: '/' });
            removeCookie('access_token', { path: '/' });
            dispatch(logoutUser({data: {}}));
            dispatch(setSessionDetails({data: {}}));
            dispatch(setLoginStatus({data: false}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(err);
          });
    }else{
      console.log("4444");
      removeCookie('mobile', { path: '/' });
            removeCookie('session', { path: '/' });
            removeCookie('access_token', { path: '/' });
            dispatch(logoutUser({data: {}}));
        dispatch(setSessionDetails({data: {}}));
        dispatch(setLoginStatus({data: false})); 
        dispatch(setLoadingStatus({status: false}));  
      }
}

export const createSession = (dispatch, cookies, setCookie, accessToken) => {
  dispatch(setLoadingStatus({status: true}));  

  if(accessToken != null){
    axios({
      method: "post",
      url: API_CREATE_SESSION,
      data: {},
      headers: { "Content-Type": "multipart/form-data",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {

      if(response.status === 200){
        dispatch(setSessionDetails({data: response.data.data}));
        setCookie('session', response.data.data.session_id, { path: '/' });
        dispatch(setLoginStatus({data: true}));
        dispatch(clearOTPState({data: {}}));

      }else{
        dispatch(setLoginStatus({data: false}));
        dispatch(clearOTPState({data: {}}));
        dispatch(setLoadingStatus({status: false}));  

      }
    })
    .catch((response) => {

      dispatch(setLoginStatus({data: false}));
      dispatch(clearOTPState({data: {}}));
      dispatch(setLoadingStatus({status: false}));  

    });
  }else{
    dispatch(setLoginStatus({data: false}));
    dispatch(clearOTPState({data: {}}));
    dispatch(setLoadingStatus({status: false}));  

  }

}

export const generateAccessToken = (dispatch, cookies, setCookie, userId, clientId, clientSecret) => {
  const accessTokenUrl = API_GENERATE_ACCESS_TOKEN;

  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'password');
  bodyFormData.append('scope', 'user');
  bodyFormData.append('username', userId);
  bodyFormData.append('password', 'valid');

  const username = clientId;
  const password = clientSecret;
  console.log(username);
  console.log(password);
  const token = `${username}:${password}`;
  const encodedToken = Buffer.from(token).toString('base64');
  dispatch(setLoadingStatus({status: true}));  

  axios({
    method: "post",
    url: accessTokenUrl,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data",'Authorization': `Basic ${ encodedToken}` },
  })
  .then((accessTokenResponse)  => {
    if(accessTokenResponse.status === 200){
      setCookie("access_token",accessTokenResponse.data.access_token, { path: '/' });
      createSession(dispatch, cookies, setCookie, accessTokenResponse.data.access_token);
      fetchUserDetails(dispatch, cookies);
    }else{
      setCookie("access_token","", { path: '/' });
      dispatch(setLoadingStatus({status: false}));  
    }
  })
  .catch((response) => {
    setCookie("access_token","", { path: '/' });
    dispatch(setLoadingStatus({status: false}));  
  });        
}

export const getAccessTokenFromCookie = (dispatch, cookies) => {

    let accessToken = null; 
    if(cookies.access_token != null && cookies.access_token !== 'null' && cookies.access_token !== ''){
      // if(cookies.session != null && cookies.session !== 'null' && cookies.session !== ''){
        accessToken = cookies.access_token;
        dispatch(setAccessToken({data: accessToken}));
    }

    return accessToken;
};