import { useNavigate } from 'react-router-dom';
// @mui
import { Card, Grid2, Typography } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../../../../components/Page';
import Carousel from '../../../../components/Carousel';
import { getBannerImages } from '../../../../store/reducers/config_reducer';

import './CategoryPage.css';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import { APP_NAME } from '../../../../constants/app_constants';
import EventList from '../../../../components/EventList/EventList';
import { CATEGORY_DATA, FILTER_PARAMS, MOCK_EVENT } from '../../../../data/local/mock';
import HeaderDark from '../../../../components/Header/HeaderDark';
import Iconify from '../../../../components/Iconify';

export default function CategoryPage() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const bannerImages = useSelector(state => getBannerImages(state)); 

  const events = [
    MOCK_EVENT, MOCK_EVENT, MOCK_EVENT, MOCK_EVENT, MOCK_EVENT
  ]

  const categoryData = CATEGORY_DATA;

  const filters = FILTER_PARAMS;
  const pagination = {
    'page': 1,
    'per_page': 10,
    'total_pages': 13
  }

  const background = "https://static.vecteezy.com/system/resources/previews/024/603/816/non_2x/rock-music-concert-background-illustration-ai-generative-free-photo.jpg";

  return (
    <Page title={APP_NAME}>
        <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid2 className={'category-header'} container style={{minHeight:'50vh', width:'100%', backgroundImage: `url(${background})`}}>
                <HeaderDark />
                <Grid2 container direction={'column'} justifyContent={'center'} px={8}>
                  <Typography variant="h3">{categoryData.title}</Typography>
                </Grid2>
            </Grid2>

            {
              categoryData && categoryData.top_performers && 
              <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'} py={4} px={8}>
                <Typography variant="h6" py={2}>{`Top performers`}</Typography>
                <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} columnSpacing={4} rowSpacing={2}>
                  {
                    categoryData.top_performers.map((performer, index) => {
                      return <Card className={'category-top-performer-card'} onClick={() => {
                        navigate(performer.link);
                      }}>
                        <Grid2 container direction={'row'} justifyContent={'space-between'} alignItems={'center'} columnSpacing={4}>
                          
                          <Grid2 container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} columnSpacing={1}>

                            <img src={performer.image_url} alt={performer.name} size={24} style={{borderRadius:'50px'}} />

                            <Typography variant="body1" sx={{minWidth:'100px'}}>{performer.name}</Typography>

                          </Grid2>


                          <Iconify icon={'eva:heart-outline'} />

                        </Grid2>
                      </Card>
                    })
                  }
                </Grid2>
              </Grid2>
            }

            <Grid2 container my={4} px={8} direction={'column'} justifyContent={'center'} sx={{minHeight:'500px', width:'100%'}}>
              <EventList events={events} pagination={pagination} filters={filters} />
            </Grid2>
            

            <Footer />
        </Grid2>
    </Page>
      
  );
}
