
// export const APP_ENV = 'development';
export const APP_ENV = 'production';

export const APP_NAME = 'Tickit';
export const APP_VERSION = '1.0.0';
export const DEVICE_TYPE = 'WEB';

export const ENCRYPTION_KEY = "WCBoaDQIQAgceGg8dFAkMDBEOECEZCxg";
export const ENCRYPTION_IV = "tickit@password20";

// Google Analytics
export const GA_TRACKING_ID = (APP_ENV == 'production') ? "G-EEXBJEMK5M" : "G-VD7YXR1S45";
export const GTM_ID = (APP_ENV == 'production') ? "" : "";

// AWS Config
export const AWS_REGION = "ap-south-1";
export const AWS_S3_BUCKET = "tickit-dashboard-bucket";
export const AWS_S3_CREDENTIAL = {
    accessKeyId: 'AKIA6GSNGYI2KMVZUTMT',
    secretAccessKey: '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',
};

export const RAZORPAY_KEY = "rzp_test_LFH0TySRmo8gc4";
// export const RAZORPAY_KEY = "";

export const BASIC_AUTH_USERNAME = "bytes_open_User";
export const BASIC_AUTH_PASSWORD = "BOU_923IOWkjre87839843jdf3290843#&32";

export const STATUS = {
    LOADING : 'loading',
    SUCCESS: 'success',
    ERROR : 'error',
};

export const PAYMENT_MODE = {
    OFFLINE : 'CASH',
    ONLINE: 'online'
}

export const PAYMENT_GATEWAY = {
    CASH : {
        'name':'cash',
        'enabled' : false,
        'label': 'Cash'
    },
    PAYTM: {
        'name':'paytm',
        'enabled' : true,
        'label': 'Paytm'
    },
    RAZORPAY : {
        'name':'RAZOR_PAY',
        'enabled' : true,
        'label': 'Razorpay'
    },
    PHONEPE: {
        'name':'PHONEPE',
        'enabled' : true,
        'label': 'PhonePe'
    }
}

export const ROLES = {
    MASTER_ADMIN : 'master_admin',
    CUSTOMER: 'customer'
};

export const SNACKBAR_SEVERITY = {
    ERROR : 'error',
    INFO: 'info',
    WARNING: 'warning', 
    SUCCESS: 'success', 
};
 

export const FOOTER_LINKS = {
    ABOUT_US : 'https://www.tickit.app/about-us/',
    FAQS: 'https://www.tickit.app/#faq/',
    PRIVACY_POLICY : './terms-and-conditions/',
    FACEBOOK_URL : 'https://www.facebook.com/event-horizon-tickit/',
    INSTAGRAM_URL : 'https://www.instagram.com/event-horizon-tickit/',
    TWITTER_URL : 'https://twitter.com/event-horizon-tickit',
    LINKEDIN_URL : 'https://www.linkedin.com/company/event-horizon-tickit/',
    YOUTUBE_URL : 'https://www.youtube.com/channel/event-horizon-tickit',
}

export const CHECKOUT_TYPE = {
    STANDARD : 'STANDARD',
    QUICK_CHECKOUT : 'QUICK_CHECKOUT'
}

export const FEEDBACK_ITEM_OPTIONS = [
    {
        'key' : 1,
        'label' : 'Bad',
        'value': 1,
        'icon': 'fa-frown-o',
        'category': 'bad',
    },
    {
        'key' : 2,
        'label' : 'Fair',
        'value': 2,
        'icon': 'fa-frown-o',
        'category': 'bad',
    },
    {
        'key' : 3,
        'label' : 'Average',
        'value': 3,
        'icon': 'fa-meh-o',
        'category': '',
    },
    {
        'key' : 4,
        'label' : 'Good',
        'value': 4,
        'icon': 'fa-smile-o',
        'category': 'good',
    },
    {
        'key' : 5,
        'label' : 'Excellent',
        'value': 5,
        'icon': 'fa-smile-o',
        'category': 'love',
    }
];

export const PAGE_TYPE = {
    "DEFAULT": {
        'key': 'home',
    },
    "HOME" : {
        'key': 'home',
    },
    "CATEGORY": {
        'key': 'category',
    },
    "ARTIST" : {
        'key': 'artist',
    },
    "CITY" : {
        'key': 'city',
    }
}

export const SUPPORT_DEFAULT_CONTACT = "+91-9879879870";
export const SUPPORT_DEFAULT_EMAIL = "support@tickit.world";

export const SOCIAL_MEDIA_LINKS = {
    'facebook' : '',
    'instagram': '',
    'linkedin': '',
    'twitter': '',
    'youtube': ''
}

export const DEFAULT_SUPPORT_DETAILS = {
    'contact': SUPPORT_DEFAULT_CONTACT,
    'email' : SUPPORT_DEFAULT_EMAIL
  }

export const DEFAULT_SITE_CONFIG = {
    "social_media_links": {
        "facebook": null,
        "instagram": null,
        "twitter": null,
        "linkedin": null,
        "youtube": null,
        "discord": null
    },
    "support_details": {
        "support_email": SUPPORT_DEFAULT_EMAIL,
        "support_number": SUPPORT_DEFAULT_CONTACT
    },
    "name": "Tickit",
    "banner_images": [
        {
            "id": 1,
            "image_url": "https://static.vecteezy.com/system/resources/previews/024/603/816/non_2x/rock-music-concert-background-illustration-ai-generative-free-photo.jpg",
            "link": "concert-tickets/diljit-dosanjh",
            "title": "Find live events near you",
            "subtitle": ""
        }
    ]
}