import AWS from "aws-sdk";
import {S3Client, ListBucketsCommand, GetObjectCommand, HeadObjectCommand, PutBucketCorsCommand} from '@aws-sdk/client-s3';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import { AWS_REGION, AWS_S3_BUCKET, AWS_S3_CREDENTIAL } from "../constants/app_constants";
import { isMobile } from "react-device-detect";

export const getS3Url = async (req) => {

    const s3 = new S3Client({ region: AWS_REGION, credentials: AWS_S3_CREDENTIAL })

    const _response = [];
    for (const i in req){
        try{
            const s3Params = {
                Bucket: AWS_S3_BUCKET,
                Key: isMobile ? req[i]['image']['file_path_mobile'] : req[i]['image']['file_path_web'],
                ContentType: isMobile ? req[i]['image']['file_type_mobile'] : req[i]['image']['file_type_web'],
                // ACL: 'bucket-owner-full-control'
            };

            // const putBucketCorsCommand = new PutBucketCorsCommand(s3Params);
            // const x = await s3.send(putBucketCorsCommand);

            // console.log(x);

            // const headObjectCommand = new HeadObjectCommand(s3Params);
            // const response = await s3.send(headObjectCommand);

            // console.log(response);

            const command = new GetObjectCommand(s3Params);
            // await headObject(s3Params).promise();
            const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
            if (isMobile) {                
                _response.push({
                    ...req[i],
                    'image': {
                        ...req[i]['image'],
                        'file_url_mobile': url
                    }
                })
            }else{
                _response.push({
                    ...req[i],
                    'image': {
                        ...req[i]['image'],
                        'file_url_web': url
                    }
                })
            }

        }catch(err){
            console.error(err);
            console.log("------------------------------111111111111");
            if (isMobile) {
                _response.push({
                    ...req[i],
                    'image': {
                        ...req[i]['image'],
                        'file_url_mobile': null,
                    }
                })

            }else{
                _response.push({
                    ...req[i],
                    'image': {
                        ...req[i]['image'],
                        'file_url_web': null,
                    }
                })

            }
        }
    } 

    return _response;

}



export const getSingleS3Url = async (req) => {

    const s3 = new S3Client({ region: AWS_REGION, credentials: AWS_S3_CREDENTIAL })
    let url = null;

    try{
        const s3Params = {
            Bucket: AWS_S3_BUCKET,
            Key:  req['file_path'] ,
            ContentType: req['file_type'],
            // ACL: 'bucket-owner-full-control'
        };

        const command = new GetObjectCommand(s3Params);
        url = await getSignedUrl(s3, command, { expiresIn: 3600 });

        console.log(url);
    }catch(err){
        console.log("-----------");
        console.log(req);
        console.error(err);
        console.log("-----------");
    }

    return url;

}

