import { createSlice } from '@reduxjs/toolkit'
// import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    listingData: null,
    listingDetail: null,
    saveListingResponse: null,
  },
  reducers: {
    setListingData(state,action) {
      let _data = [];
      if (action.payload.pagination?.page > 1 ){
          _data = [
              ...state.eventData.data,
              ...action.payload.data
          ];
      }else{
          _data = action.payload.data;
      }
      state.listingData = {
          'data': _data,
          'pagination': action.payload.pagination 
      }
    },
    setListingDetail(state,action) {
      state.listingDetail = action.payload.data; 
    },
    setSaveListingResponse(state,action) {
      state.saveListingResponse = action.payload.data;
    },
    updateListingFollowStatus(state, action) {
      try{
        const _listingData = state.listingData.data;
        _listingData.forEach(element => {
            if (element.id === action.payload.listing_id){
                element.is_following = action.payload.status
            }
        });
        state.listingData.data = _listingData;
      }catch(e){
          console.log(e);
      }
    },
    resetListingState(state, action){
      state.listingData = null;
      state.listingDetail = null;
      state.saveListingResponse = null;
    }
  }
})


export const getListingData = (state) => {
    try{
        return state.listing.listingData;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getListingList = (state) => {
    try{
        return state.listing.listingData.data;
    }catch(e){
        console.log(e);
    }
    return [];
}


export const getSaveListingResponse = (state) => {
  try{
      return state.listing.saveListingResponse;
  }catch(e){
      console.log(e);
  }
  return [];
}

export const getListingPagination = (state) => {
    try{
        return state.listing.listingData.pagination;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const getListingDetail = (state) => {
    try{
        return state.listing.listingDetail;
    }catch(e){
        console.log(e);
    }
    return null;
}

export const { setListingData, setListingDetail, resetListingState, updateListingFollowStatus, setSaveListingResponse
} = listingSlice.actions
export default listingSlice.reducer