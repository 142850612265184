import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Grid2 } from '@mui/material';

// react redux
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
// components

import './EventList.css';
import EventListCard from './EventListCard';

export default function EventList({events, pagination, filters}) { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  return (    
    <Grid2 container direction={'column'} justifyContent={'center'} alignItems={'flex-start'} sx={{width:'100%'}}>

        {events && events.length>0 && 
            <>
                <Grid2 container direction={'column'} justifyContent={'center'} sx={{width:'100%'}} rowSpacing={2} padding={2}>
                    {
                        events.map((event, i) => {
                            return <EventListCard row={i} event={event} />
                        })
                    }
                </Grid2>

                <Grid2 container flexDirection={'row'} justifyContent={'center'} sx={{width:'100%'}}>
                    {
                        events.pagination && (events.pagination.page < events.pagination.total_pages) && 
                        <Button variant='outlined'>
                            See more
                        </Button>
                    }
                </Grid2>
            </>
        }

    </Grid2>
  );
}
